import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsService} from '../services/notifications.service';
import {MessageService} from 'primeng/api';
import {ProfileService} from '../services/profile.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public env = environment;

  notifications: Observable<any[]>;
  loading = true;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private notificationsService: NotificationsService,
      private messageService: MessageService,
      private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.notifications = this.notificationsService.notifications$.pipe(
        map((data) => {
          return data.map((notification) => {
            notification.readAt = notification.read_by.find((user) => user.readerId === this.profileService.getUserData().id);
            return notification;
          });
        })
    );

    this.notificationsService.fetchNotifications()
        .then((data) => {
          this.loading = false;
        });
  }

    readNotification(notification) {
        this.notificationsService.readNotification(notification.id)
            .then((data) => {
                this.messageService.add(
                    {
                        key: 'globalToast',
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Notification Read',
                        life: 3000
                    });
            });
    }

    accessNotification(notification) {
      if (!notification.readAt) {
          this.notificationsService.readNotification(notification.id);
      }
      if (notification.type === 'bookings') {
        this.router.navigate(
            ['../' , 'bookings',  notification.data.bookingId],
            { relativeTo: this.route }
        );
      } else if (notification.type === 'invites') {
        this.router.navigate(
            ['../' , 'user'],
            { relativeTo: this.route, queryParams: { scrollTo: 'userBands' }}
        );
      }
    }


    readAllNotifications(notifications) {
        this.notificationsService.readAllNotifications(notifications.map(notif => notif.id))
            .then((data) => {
                this.messageService.add(
                    {
                        key: 'globalToast',
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'All Notifications Read',
                        life: 30000
                    });


            });
    }

    refresh() {
        this.notificationsService.fetchNotifications()
            .then((data) => {
                this.messageService.add(
                    {
                        key: 'globalToast',
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Refreshed',
                        life: 3000
                    });
            });
    }

}
