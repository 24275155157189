<div class="grid grid-nogutter " *ngIf="notifications | async as notificationsData">

    <div class="col-12 " *ngIf="false" >
        <p-toolbar>
            <div class="p-toolbar-group-left">
            </div>

            <div class="p-toolbar-group-right">

                <p-button class = "secondary"
                          label="Mark as read"
                          icon="pi pi-times"
                          (click)="readAllNotifications(notificationsData)"
                ></p-button>

            </div>
        </p-toolbar>
    </div>

    <div class="col-12">


        <p-dataView #dv
                    [value]="notificationsData"
                    [paginator]="true"
                    [rows]="8"
                    [showFirstLastIcon]="false"
                    [loading]="loading"
                    layout="list">

            <ng-template let-notification pTemplate="listItem">


                <div class="col-12 md:col-6 notification-elem" [ngClass]="{'unread-notification' : !notification.readAt}">
                    <div class="product-list-item" (click)="accessNotification(notification)">

                        <span class="unread-dot"></span>

                        <ng-template
                            [ngIf]="notification.type === 'bookings'"
                        >
                            <ng-template
                                    [ngIf]="(
                                    notification.data.status === 'studio-declined' ||
                                    notification.data.status === 'studio-canceled' ||
                                    notification.data.status === 'studio-confirmed'
                                )"
                            >
                                <img
                                        [src]="notification.data.hasOwnProperty('studioLogo') ? notification.data.studioLogo : 'assets/images/bandbeat/sample_user_logo.png'"
                                        [alt]="notification.type"
                                />
                            </ng-template>
                            <ng-template
                                    [ngIf]="(
                                    notification.data.status === 'planning-complete'
                                )"
                            >
                                <img
                                        [src]="notification.data.hasOwnProperty('teamAvatar') ? notification.data.teamAvatar : 'assets/images/bandbeat/sample_user_logo.png'"
                                        [alt]="notification.type"
                                />
                            </ng-template>
                            <ng-template
                                [ngIf]="!(
                                    notification.data.status === 'studio-declined' ||
                                    notification.data.status === 'planning-complete' ||
                                    notification.data.status === 'studio-canceled' ||
                                    notification.data.status === 'studio-confirmed'
                                )"
                            >
                                <img
                                        [src]="notification.sender.hasOwnProperty('avatar') ? notification.sender.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                                        [alt]="notification.type"
                                />
                            </ng-template>


                        </ng-template>

                        <img
                                *ngIf="notification.type === 'general'"
                                [src]="'assets/images/sample_user.png'"
                                [alt]="notification.type"
                        />
                        <img
                                *ngIf="notification.type === 'invites'"
                                [src]="notification.sender.hasOwnProperty('avatar') ? notification.sender.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                                [alt]="notification.type"
                        />
                        <img
                                *ngIf="
                                    notification.type !== 'bookings' &&
                                    notification.type !== 'invites' &&
                                    notification.type !== 'general'
                                "
                                [src]="'assets/images/sample_user.png'"
                                [alt]="notification.type"
                        />
                        <div class="product-list-detail tn-text-simple">
                            <div *ngIf="notification.type == 'bookings'">

                                <ng-template
                                        [ngIf]="notification.data.category === 'newBookingNotification'"
                                >
                                    <ng-template
                                            [ngIf]="notification.data.status === 'planning'"
                                    >
                                        <span >{{notification.sender.name}}</span>
                                        <span> requested a new </span>
                                        <span class="keyword">{{notification.data.teamName}} {{notification.data.serviceType}}</span>
                                        <span>! Review & respond!</span>
                                    </ng-template>
                                    <ng-template
                                            [ngIf]="notification.data.status === 'pending-confirmation'"
                                    >
                                        <ng-template
                                                [ngIf]="notification.data.team"
                                        >
                                            <span >{{notification.data.teamName}}</span>
                                            <span> sent a </span>
                                            <span class="keyword">{{notification.data.serviceType}}</span>
                                            <span> request at </span>
                                            <span>{{notification.data.studioName}}! We will let you know when {{notification.data.studioName}} responds!</span>
                                        </ng-template>

<!--                                        <ng-template-->
<!--                                                [ngIf]="!notification.data.team"-->
<!--                                        >-->
<!--                                            <span style="font-weight: 700;font-size: 16px;line-height: 20px;">{{notification.sender.name}}</span>-->
<!--                                            <span> requested a new </span>-->
<!--                                            <span class="keyword">{{notification.data.serviceType}}</span>-->
<!--                                        </ng-template>-->

                                    </ng-template>
                                    <ng-template
                                            [ngIf]="notification.data.status === 'studio-confirmed'"
                                    >

                                        <span

                                        >Your </span>
                                        <span
                                            *ngIf="notification.data.team"
                                            >{{notification.data.teamName}} </span>
                                        <span class="keyword">{{notification.data.serviceType}} </span>
                                        <span> request at {{notification.data.studioName}} was accepted!</span>
                                    </ng-template>
                                    <ng-template
                                            [ngIf]="notification.data.status === 'studio-declined' ||
                                                notification.data.status === 'studio-canceled'"
                                    >
                                            <span

                                            >Your </span>
                                            <span
                                                    *ngIf="notification.data.team"
                                                    >{{notification.data.teamName}} </span>
                                            <span class="keyword">{{notification.data.serviceType}} </span>
                                            <span> request at {{notification.data.studioName}} was {{notification.data.status === 'studio-declined' ? 'declined' : 'canceled'}}. Why not reschedule?</span>
                                    </ng-template>


                                    <ng-template
                                            [ngIf]="notification.data.status === 'user-canceled'"
                                    >
                                            <span
                                            >Your </span>
                                        <span
                                                *ngIf="notification.data.team"
                                                >{{notification.data.teamName}} </span>
                                        <span class="keyword">{{notification.data.serviceType}} </span>
                                        <span> at {{notification.data.studioName}} was canceled. Why not reschedule?</span>
                                    </ng-template>


                                    <ng-template
                                            [ngIf]="
                                                notification.data.status === 'user-declined'
                                            "
                                    >
                                        <span >{{notification.sender.name}}</span>
                                        <span> just canceled </span>
                                        <span
                                                *ngIf="notification.data.team"
                                                >the {{notification.data.teamName}} </span>
                                        <span *ngIf="!notification.data.team">his/her </span>
                                        <span class="keyword">{{notification.data.serviceType}}</span>
                                        <span> booking!</span>
                                    </ng-template>

                                    <ng-template [ngIf]="notification.data.status === 'planning-complete'">
                                        <span>All members accepted your </span>
                                        <span class="keyword">{{notification.data.teamName}} {{ notification.data.serviceType}} </span>
                                        <span> request! Decide on your payment method & send the request to {{notification.data.studioName}}</span>
                                    </ng-template>

                                </ng-template>

                                <ng-template
                                        [ngIf]="notification.data.category === 'memberBookingResponseNotification'"
                                >
                                    <ng-template
                                    [ngIf]="notification.data.status === 'accept'"
                                    >
                                        <span >{{notification.sender.name}}</span>
                                        <span> accepted your {{notification.data.teamName}}</span>
                                        <span class="keyword"> {{notification.data.serviceType}}</span>
                                        <span> request! </span>
                                    </ng-template>
                                    <ng-template
                                            [ngIf]="notification.data.status === 'decline'"
                                    >
                                        <span >{{notification.sender.name}}</span>
                                        <span> declined your {{notification.data.teamName}}</span>
                                        <span class="keyword"> {{notification.data.serviceType}}</span>
                                        <span> request! </span>
                                        <span class="keyword">Why not reschedule?</span>
                                    </ng-template>

                                </ng-template>

                            </div>



                            <div *ngIf="notification.type == 'invites'">
                                <ng-template
                                        [ngIf]="notification.data.status === 'pending'"
                                >
                                    <span >{{notification.sender.name}}</span>
                                    <span> invited you to join </span>
                                    <span class="keyword">{{notification.data.teamName}}</span>
                                    <span>!</span>
                                </ng-template>
                                <ng-template
                                        [ngIf]="notification.data.status === 'accepted'"
                                >
                                    <span >{{notification.sender.name}}</span>
                                    <span> accepted your </span>
                                    <span class="keyword">{{notification.data.teamName}}</span>
                                    <span> invitation!</span>
                                </ng-template>
                                <ng-template
                                        [ngIf]="notification.data.status === 'denied'"
                                >
                                    <span >{{notification.sender.name}}</span>
                                    <span> declined your invitation to join </span>
                                    <span class="keyword">{{notification.data.teamName}}</span>
                                    <span>!</span>
                                </ng-template>
                            </div>


                            <div *ngIf="notification.type == 'general'">
                                <span>{{notification.message}}</span>
                            </div>
                            <div class="product-description">On {{notification.createdAt | dateParser}}</div>
                        </div>

                    </div>
                </div>

            </ng-template>

        </p-dataView>

    </div>



</div>

