import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AsyncPipe, DatePipe, HashLocationStrategy, LocationStrategy, NgIf, PathLocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from '@fullcalendar/angular';
import {GalleriaModule} from 'primeng/galleria';
import {ImageModule} from 'primeng/image';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KnobModule} from 'primeng/knob';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
// import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

// Application Components
import {AppCodeModule} from './code/app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './theme/config/app.config.component';
import {AppMenuComponent} from './menu/app.menu.component';
import {AppMenuitemComponent} from './menu/app.menuitem.component';
import {AppTopbarMenuComponent} from './topbar/app.topbarmenu.component';
import {AppTopBarComponent} from './topbar/app.topbar.component';
import {AppFooterComponent} from './footer/app.footer.component';

// Demo pages
import {AppNotfoundComponent} from './theme/pages/app.notfound.component';
import {AppErrorComponent} from './theme/pages/app.error.component';
import {AppAccessdeniedComponent} from './theme/pages/app.accessdenied.component';


// Application services
import {MenuService} from './menu/app.menu.service';
import {TopbarMenuService} from './topbar/app.topbarmenu.service';

import { HeaderComponent } from './header/header.component';
import { LandingComponent } from './landing/landing.component';
import { ListingComponent } from './listing/listing.component';
import { FilterStudioNamePipe } from './common/pipes/filter-studio-name.pipe';
import { FilterStudioServicePipe } from './common/pipes/filter-studio-service.pipe';
import {GMapModule} from 'primeng/gmap';
import { MapOverlaysPipe } from './common/pipes/map-overlays.pipe';
import { StudioComponent } from './studio/studio.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import {MessageService, SharedModule} from 'primeng/api';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {NewBookingComponent} from './new-booking/new-booking.component';
import {BookingServiceComponent} from './new-booking/booking-info/booking-service/booking-service.component';
import {BookingInstrumentsComponent} from './new-booking/booking-info/booking-instruments/booking-instruments.component';
import {BookingSummaryComponent} from './new-booking/booking-summary/booking-summary.component';
import {BookingRoomComponent} from './new-booking/booking-info/booking-room/booking-room.component';
import {BookingSettingsComponent} from './new-booking/booking-info/booking-settings/booking-settings.component';
import {BookingInfoComponent} from './new-booking/booking-info/booking-info.component';
import { BookingRequestComponent } from './new-booking/booking-request/booking-request.component';
import {httpInterceptorProviders} from './common/interceptors';
import { NewBandComponent } from './new-band/new-band.component';
import { BookingMembersComponent } from './new-booking/booking-info/booking-members/booking-members.component';
import { BookingsComponent } from './bookings/bookings.component';
import {FilterBookingsPipe} from './common/pipes/filter-bookings.pipe';
import { NotificationsComponent } from './notifications/notifications.component';
import {DateParserPipe} from './common/pipes/date-parser.pipe';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import {UserInstrumentsComponent} from './user-profile/user-instruments/user-instruments.component';
import {UserBandsComponent} from './user-profile/user-bands/user-bands.component';
import { BandProfileComponent } from './band-profile/band-profile.component';
import { DurationParserPipe } from './common/pipes/duration-parser.pipe';
import { StudioAdvancedComponent } from './common/cards/studio-advanced/studio-advanced.component';
import { StudioSimpleComponent } from './common/cards/studio-simple/studio-simple.component';
import { BookingOverviewComponent } from './common/cards/booking-overview/booking-overview.component';
import { BandSimpleComponent } from './common/cards/band-simple/band-simple.component';
import { BandBookingOverviewComponent } from './common/cards/band-booking-overview/band-booking-overview.component';
import { InstrumentComponent } from './common/cards/instrument/instrument.component';
import { EquipmentComponent } from './common/cards/equipment/equipment.component';
import { WizardStepComponent } from './common/cards/wizard-step/wizard-step.component';
import { OpeningHoursComponent } from './common/cards/opening-hours/opening-hours.component';
import { ServicesCardComponent } from './common/cards/services-card/services-card.component';
import { FilledIconComponent } from './theme/utilities/filled-icon.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {WaveAnimationComponent} from './common/wave-animation/wave-animation.component';
import { LoginPopupComponent } from './login/login-popup/login-popup.component';
import {ClipboardModule} from 'ngx-clipboard';
import { TitleCaseExtendedPipePipe } from './common/pipes/title-case-extended-pipe.pipe';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TimeDayPipe } from './common/pipes/time-day.pipe';
import { VerbalizeBookingStatusPipe } from './common/pipes/verbalize-booking-status.pipe';
import { SearchListingComponent } from './listing/search-listing/search-listing.component';
import { CommentComponent } from './common/comment/comment.component';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from '@abacritt/angularx-social-login';
import { SocialCallbackComponent } from './social-callback/social-callback.component';
import {environment} from '../environments/environment';
import { NormalizeBookingStatusPipe } from './common/pipes/normalize-booking-status.pipe';
import { PaymentCallbackComponent } from './payment-callback/payment-callback.component';
import { UserPaymentsComponent } from './user-payments/user-payments.component';
import {TcComponent} from './footer/tc/tc.component';
import {PrivacyAndPolicyComponent} from './footer/privacy-and-policy/privacy-and-policy.component';
import {ContactComponent} from './footer/contact/contact.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {ShareModule} from 'ngx-sharebuttons';
import { SocialShareComponent } from './social-share/social-share.component';
import { FilterStudioCountryPipe } from './common/pipes/filter-studio-country.pipe';
import {TrimDirective} from './common/directives/TrimDirective';
import {PixelModule} from "ngx-pixel";
import { SortingListingPipe } from './common/pipes/sorting-listing.pipe';
import { SetupWizardComponent } from './setup-wizard/setup-wizard.component';
import {RouterOutlet} from "@angular/router";
import { WizardUserInfoComponent } from './setup-wizard/wizard-user-info/wizard-user-info.component';
import { WizardUserMediaComponent } from './setup-wizard/wizard-user-media/wizard-user-media.component';
import { WizardUserBandComponent } from './setup-wizard/wizard-user-band/wizard-user-band.component';
import { TnStudioCardComponent } from './common/cards/tn-studio-card/tn-studio-card.component';
import { TnOpeningHoursComponent } from './common/cards/tn-opening-hours/tn-opening-hours.component';
import { TnInstrumentComponent } from './common/cards/tn-instrument/tn-instrument.component';
import { TnEquipmentComponent } from './common/cards/tn-equipment/tn-equipment.component';
import { TnServiceComponent } from './common/cards/tn-service/tn-service.component';
import {MenuResponsiveComponent} from "./topbar/menu-responsive/menu-responsive.component";
import { TnBandSimpleComponent } from './common/cards/tn-band-simple/tn-band-simple.component';
import { TnBandMemberComponent } from './common/cards/tn-band-member/tn-band-member.component';
import { TnRoomCardComponent } from './common/cards/tn-room-card/tn-room-card.component';
import { BandMembersParserPipe } from './common/pipes/band-members-parser.pipe';
import { EquipmentParserPipe } from './common/pipes/equipment-parser.pipe';
import { ViewMorePipe } from './common/pipes/view-more.pipe';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { FilterBandsInvitesPipe } from './common/pipes/filter-bands-invites.pipe';
import { ReadMorePipe } from './common/pipes/read-more.pipe';
import { TnBandInviteComponent } from './common/cards/tn-band-invite/tn-band-invite.component';
import { FilterPaymentsPipe } from './common/pipes/filter-payments.pipe';
import { BookingPaymentComponent } from './new-booking/booking-payment/booking-payment.component';
import { FilterBookingTypesPipe } from './common/pipes/filter-booking-types.pipe';
import { FilterAccountTypesPipe } from './common/pipes/filter-account-types.pipe';
import {GoogleTagManagerModule} from "angular-google-tag-manager";




@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarGroupModule,
        AvatarModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        // MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        GMapModule,
        ClipboardModule,
        SocialLoginModule,
        LazyLoadImageModule,
        PixelModule.forRoot({enabled: environment.facebookTracking, pixelId: '296922669768308'}),
        GoogleTagManagerModule.forRoot({
            id: environment.googleTracking ? 'GTM-K8F6CBWD' : '',
        }),

        ShareModule,

        NgxWebstorageModule.forRoot(),
        CheckboxModule,
        CheckboxModule,
        NgIf,
        NgIf,
        RouterOutlet,
        StepsModule,
        AsyncPipe,
        ButtonModule,
        DropdownModule,
        FormsModule,
        InputNumberModule,
        InputTextModule,
        NgIf,
        SharedModule,
        AvatarModule,
        ButtonModule,
        FileUploadModule,
        RippleModule,
        ButtonModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopbarMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        HeaderComponent,
        LandingComponent,
        ListingComponent,
        FilterStudioNamePipe,
        FilterStudioServicePipe,
        MapOverlaysPipe,
        StudioComponent,
        LoginComponent,
        SignupComponent,
        NewBookingComponent,
        BookingInfoComponent,
        BookingServiceComponent,
        BookingSettingsComponent,
        BookingRoomComponent,
        BookingInstrumentsComponent,
        BookingSummaryComponent,
        BookingRequestComponent,
        NewBandComponent,
        BookingMembersComponent,
        BookingsComponent,
        NotificationsComponent,
        FilterBookingsPipe,
        DateParserPipe,
        UserSettingsComponent,
        UserProfileComponent,
        UserInstrumentsComponent,
        UserBandsComponent,
        BandProfileComponent,
        DurationParserPipe,
        BandProfileComponent,
        StudioAdvancedComponent,
        StudioSimpleComponent,
        BookingOverviewComponent,
        BandSimpleComponent,
        BandBookingOverviewComponent,
        InstrumentComponent,
        EquipmentComponent,
        WizardStepComponent,
        OpeningHoursComponent,
        ServicesCardComponent,
        WaveAnimationComponent,
        FilledIconComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        LoginPopupComponent,
        TitleCaseExtendedPipePipe,
        LoginPopupComponent,
        ChangePasswordComponent,
        TimeDayPipe,
        VerbalizeBookingStatusPipe,
        SearchListingComponent,
        CommentComponent,
        SocialCallbackComponent,
        NormalizeBookingStatusPipe,
        PaymentCallbackComponent,
        UserPaymentsComponent,
        TcComponent,
        PrivacyAndPolicyComponent,
        ContactComponent,
        SocialShareComponent,
        FilterStudioCountryPipe,
        TrimDirective,
        SortingListingPipe,
        SetupWizardComponent,
        WizardUserInfoComponent,
        WizardUserMediaComponent,
        WizardUserBandComponent,
        SortingListingPipe,
        TnStudioCardComponent,
        TnOpeningHoursComponent,
        TnInstrumentComponent,
        TnEquipmentComponent,
        TnServiceComponent,
        MenuResponsiveComponent,
        TnServiceComponent,
        TnBandSimpleComponent,
        TnBandMemberComponent,
        TnRoomCardComponent,
        BandMembersParserPipe,
        EquipmentParserPipe,
        ViewMorePipe,
        UserPanelComponent,
        FilterBandsInvitesPipe,
        ReadMorePipe,
        TnBandInviteComponent,
        FilterPaymentsPipe,
        BookingPaymentComponent,
        FilterBookingTypesPipe,
        FilterAccountTypesPipe
    ],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            environment.googleOauth,
                            {oneTapEnabled: false}
                        )
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(
                            environment.facebookOauth
                        )
                    }
                ],
                onError: (err) => {
                    console.log('=======')
                    console.error(err);
                    console.log('=======')
                }
            } as SocialAuthServiceConfig,
        },
        MenuService, TopbarMenuService,
        MessageService, DatePipe,
        httpInterceptorProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
