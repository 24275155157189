import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {StudiosService} from '../services/studios.service';
import {combineLatest, fromEvent, Observable} from 'rxjs';
import {HelperService} from '../services/helper.service';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {ProfileService} from '../services/profile.service';
import * as countriesList from 'countries-list';
import {environment} from '../../environments/environment';
import {AppMainComponent} from "../app.main.component";

declare var google: any;

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit , AfterViewChecked {


  loading = true;

  studios: Observable<any[]>;


  mapView = false;

  availableServices: any[] = [
    {name: 'All', code: 'all'},
    {name: 'Rehearsal', code: 'rehearsal'},
    {name: 'Recording', code: 'recording'}
  ];
  selectedService = 'all';

  availableSortings: any[] = [
    {name: 'Default', code: 'default'},
    // {name: 'Name ASC', code: 'labelASC'},
    // {name: 'Name DESC', code: 'labelDESC'},
    {name: 'Lowest Price', code: 'maxPriceASC'},
    {name: 'Highest Price', code: 'maxPriceDESC'},
    {name: 'Location Closest', code: 'distanceASC'},
    // {name: 'Distance DESC', code: 'distanceDESC'}
  ];

  selectedSorting = 'default';

  searchText;

  mapOptions: any = {
    center: {lat: 37.9908697, lng: 23.7208298},
    zoom: 12,
    styles: [
      { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#263c3f' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#6b9a76' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#2f3948' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }],
      },
    ],
  };
  gmap;

  firstRun = true;
  // newRegistration = false;

  dialogVisible = false;
  selectedMarker: any;

  @ViewChild('locationSearch') locationSearch: ElementRef;
  locationAutoComplete;
  locationFound;

  searchDataView = false;

  public countries: any[];
  public selectedCountry = 'All';
  public env = environment;

  bookTime;
  bookDate;
  bookLocation;
  bookDuration;
  availableDurations: any[] = [
    {value: 1, label: '1 hour'},
    {value: 2, label: '2 hours'},
    {value: 3, label: '3 hours'},
    {value: 4, label: '4 hours'},
    {value: 5, label: '5 hours'},
    {value: 6, label: '6 hours'},
  ];
  availabilityOptions = [];

  availableTimes: any[] = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '09:00',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
  ];

  public currentDate;
  public maxDate;

  public autoCompleteResults;

  @ViewChild('studioDataView') studioDataView;

  constructor(
      private app: AppMainComponent,
      public helperService: HelperService,
      private studiosService: StudiosService,
      private profileService: ProfileService,
      private messageService: MessageService,
      private router: Router ,
      private route: ActivatedRoute
      ) {
    this.countries = this.helperService.getCountriesData().filter(el => el.name === 'Greece' || el.name === 'Cyprus');
    this.countries.unshift({
      value : 'All',
      name : 'All',
      label : 'All',
    });

    this.autoCompleteResults = this.studiosService.searchedStudios$;

    this.helperService.getUserCoordinates();
  }

  ngAfterViewChecked() {
    if (this.locationSearch && !this.locationAutoComplete) {
      this.locationAutoComplete = new google.maps.places.Autocomplete(
        this.locationSearch.nativeElement,
        {
          fields: ['formatted_address', 'geometry', 'name'],
          strictBounds: false,
          // componentRestrictions: {country:  [ 'gr' , 'cy' ]},
          // types: ['regions'],
          types: ['(regions)'],
          requestedLanguage: 'en',
          language: 'en',
        }
      );

      this.locationAutoComplete.addListener('place_changed' , () => {
        this.locationFound = this.locationAutoComplete.getPlace();
      });

    }
  }
  ngOnInit(): void {

    this.currentDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);

    if (!this.profileService.isLoggedIn()) {
      this.mapOptions.maxZoom = this.mapOptions.zoom + 2;
    } else {
      if (this.env.multipleCountriesEnabled && this.env.multipleCountriesEnabled === true) {
        if (
            this.profileService.getUserData().address &&
            ['Greece' , 'Cyprus'].includes(this.profileService.getUserData().address.country)
        ) {
          this.selectedCountry = this.profileService.getUserData().address.country;
        }
      }


      // if ( this.route.snapshot.queryParamMap.keys.length > 0) {
      //   this.newRegistration = this.route.snapshot.queryParamMap.get('newRegistration') === 'true';
      // }

    }
    this.studios =     combineLatest(
        this.helperService.userCoordinates$,
        this.studiosService.studios$.pipe(filter(std => Object.keys(std).length !== 0)),
        (one, two) => {
          return {
            hasCoordinates : one.latitude !== undefined ,
            userCoordinates : one,
            studios: two
          };
        }
    ).pipe(
        map((data) => {

          if (data.hasCoordinates && this.firstRun) {
            this.firstRun = false;
            this.mapOptions.center = {lat: data.userCoordinates.latitude, lng: data.userCoordinates.longitude};
          }
          const studiosData = data.studios;
          if (studiosData && studiosData.results) {
            studiosData.results.forEach((studio) => {

              studio = this.studiosService.mapStudioData(studio);
              studio.minPrice = 10000;
              studio.maxPrice = 0;
              studio.rooms.forEach((room) => {
                room.services.forEach((service) => {
                  if (service.price > studio.maxPrice) {
                    studio.maxPrice = service.price;
                  }
                  if (service.price < studio.minPrice) {
                    studio.minPrice = service.price;
                  }
                });
              });
              if (data.hasCoordinates) {
                if (studio.coordinates && studio.coordinates.x && studio.coordinates.y) {
                  studio.distance = this.getDistanceFromLatLonInKm(
                      studio.coordinates.x ,
                      studio.coordinates.y ,
                      data.userCoordinates.latitude ,
                      data.userCoordinates.longitude ,
                  );
                }

              }
            });
          }

          return studiosData;
        })
    );

    if(
      this.route.snapshot.queryParamMap.keys.length > 0 &&
      (
        this.route.snapshot.queryParamMap.has('coordinatesX') &&
        this.route.snapshot.queryParamMap.has('coordinatesY') &&
        this.route.snapshot.queryParamMap.has('country')
      ) ||
      (
        this.route.snapshot.queryParamMap.has('dateTime') &&
        this.route.snapshot.queryParamMap.has('duration')
      ) ||
      this.route.snapshot.queryParamMap.has('service')
    ){
      const searchObject: any = {
        coordinates: {
          x:this.route.snapshot.queryParamMap.get('coordinatesX') ,
          y:this.route.snapshot.queryParamMap.get('coordinatesY') ,
        },
        dateTime:this.route.snapshot.queryParamMap.get('dateTime') ,
        duration:this.route.snapshot.queryParamMap.get('duration') ,
        service:this.route.snapshot.queryParamMap.get('service') ,
        country: this.route.snapshot.queryParamMap.get('country')
      }

      const coordX = this.route.snapshot.queryParamMap.get('coordinatesX')
      const coordY = this.route.snapshot.queryParamMap.get('coordinatesY')

      if(coordX && coordY){ 
        setTimeout(() => {
          this.gmap.setCenter({
            lat: parseInt(coordX),
            lng: parseInt(coordY), 
          })
        }, 1000)
      }

      this.loadPresearchedStudios(searchObject)
    }else{
      this.fetchAllStudios();
    }


    this.helperService.trackViewContent('LISTING');

  }

  fetchAllStudios() {

    this.bookLocation = undefined;
    this.bookDate = undefined;
    this.bookTime = undefined;
    this.bookDuration = undefined;
    this.locationFound = undefined;
    this.selectedCountry = 'All';
    this.selectedService = 'all';


    this.loading = true;
    this.studiosService.fetchStudios(
        (
            this.profileService.isUser() ||
            !this.profileService.isLoggedIn()
        ) ? 'active' : null
    ).then((data) => {
      this.searchDataView = false;
      this.loading = false;
    });

    this.router.navigate([], {
      queryParams: {},
      queryParamsHandling: ''
    })

    this.gmap?.setCenter({lat: 37.9908697, lng: 23.7208298})
  }

  openGMaps(studio ) {
    this.helperService.openGMaps('search' , studio.coordinates);
  }

  seeDetails(studio) {
    if (studio.emptyResult) {
      return;
    }
    this.router.navigate(['..' , 'studio' , (studio.slug && studio.slug.length > 0) ? studio.slug : studio.id ]);
  }

  searchWithFilters(event) {
    this.app.onMenuButtonClick(event);
  }

  changeSorting() {
    if (this.selectedSorting === 'distanceASC' && !this.helperService.checkUserHasCoordinates()) {
      this.selectedSorting = 'default';
      this.helperService.getUserCoordinates()
          .then((userCoordinates) => {
            this.selectedSorting = 'distanceASC';
          })
          .catch((error) => {
            this.messageService.add({
              key: 'globalToast', severity: 'error', summary: 'Error', detail: error, life: 3000});
          })
    }
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg){
    return deg * (Math.PI / 180);
  }

  rejectGPS() {
    this.availableSortings = this.availableSortings.filter((sorting) => !sorting.code.startsWith('distance'));
  }

  handleOverlayClick(event) {
    if (event.overlay && event.overlay.studioData) {
      this.dialogVisible = true;
      this.selectedMarker = event.overlay.studioData;

      event.map.setCenter(event.overlay.getPosition());
    }
  }

  public getCardGridClass() {
    if (window.innerWidth < 1300) {
      return 'col-12 md:col-6 lg:col-4';
    } else {
      return 'col-12 md:col-6 lg:col-3';
    }
  }


  buildAvailabilityOptions(){
    this.availabilityOptions = [];
    this.availabilityOptions  = Object.assign([], this.availableTimes);
    const regex = new RegExp(':', 'g');
    if (this.bookDate === this.currentDate) {
      this.availableTimes.every(slot => {
        const bookDateMinutes = this.bookDate.getMinutes().length === 1 ? '0' + this.bookDate.getMinutes().toString() : this.bookDate.getMinutes();
        if (parseInt(slot.replace(regex, ''), 10) < parseInt(this.bookDate.getHours().toString() + bookDateMinutes, 10)) {
          this.availabilityOptions.splice(this.availabilityOptions.indexOf(slot), 1);
          return true;
        }
        return false;
      });
    }

    if (!this.availabilityOptions.includes(this.bookTime)){
      this.bookTime = null;
    }
  }



  searchStudios(event){
    const searchObject: any = {};
    if (
        !this.bookLocation &&
        !this.bookDate &&
        !this.bookTime &&
        !this.bookDuration &&
        !this.locationFound &&
        this.selectedCountry === 'All' &&
        this.selectedService === 'all'
    ) {
      this.messageService.add({
        key: 'globalToast',
        severity: 'error',
        summary: 'Error',
        detail: 'You need to fill at least Location to make a search!',
        life: 3000
      });
    }

    if (this.bookLocation && this.locationFound) {
      searchObject.coordinates = {
        x: this.locationFound.geometry.location.lat(),
        y: this.locationFound.geometry.location.lng(),
      };
    }else if(
      this.route.snapshot.queryParamMap.has('coordinatesX') &&
      this.route.snapshot.queryParamMap.has('coordinatesY')
    ){
      searchObject.coordinates = {
        x: this.route.snapshot.queryParamMap.get('coordinatesX'),
        y: this.route.snapshot.queryParamMap.get('coordinatesY')
      };
    }

    if (
        this.bookDate ||
        this.bookTime ||
        this.bookDuration
    ) {
      let errMsg;
      if (!this.bookTime && !this.bookDuration) {
        errMsg = 'please select Start time & Duration!';
      } else if (!this.bookDuration && !this.bookDate) {
        errMsg = 'please select Date & Duration!';
      } else if (!this.bookDate && this.bookTime) {
        errMsg = 'please select Date & Start time!';
      } else if (!this.bookDuration) {
        errMsg = 'please select Duration!';
      } else if (!this.bookTime) {
        errMsg = 'please select Start time!';
      } else if (!this.bookDate) {
        errMsg = 'please select Date!';
      }
      if (errMsg) {
        this.messageService.add({
          key: 'globalToast',
          severity: 'error',
          summary: 'Error',
          detail: errMsg,
          life: 3000
        });
      } else {
        const timeslot = this.bookDate;
        const timeslotHours = this.bookTime.split(':')[0];
        const timeslotMinutes = this.bookTime.split(':')[1];
        timeslot.setHours(timeslotHours);
        timeslot.setMinutes(timeslotMinutes);
        searchObject.dateTime = timeslot.toISOString();
        searchObject.duration = this.bookDuration;
      }

    }

    if (this.selectedService && this.selectedService !== 'all') {
      searchObject.service = this.selectedService;
    }
    if (this.selectedCountry && this.selectedCountry !== 'All') {
      searchObject.country = this.selectedCountry;
    }

    if (Object.keys(searchObject).length > 0) {
      if (
          this.profileService.isUser() ||
          !this.profileService.isLoggedIn()
      ) {
        searchObject.status = 'active';
      }
      this.studiosService.searchStudios(searchObject)
          .then((data) => {
            this.helperService.trackSearch(
                {
                  content_name: "STUDIO SEARCH",
                  search_string: JSON.stringify(searchObject)
                }
            );
            if(data.results.length > 0) {
              if(searchObject.coordinates?.x && searchObject.coordinates?.y){
                this.gmap.setCenter({lng: searchObject.coordinates.y, lat: searchObject.coordinates.x})
              }
            }
            this.passSearchParamsToUrl({...searchObject, country: this.locationFound?.formatted_address});

            this.searchDataView = true;
            this.app.onMenuButtonClick(event);
          });
    }

  }

  searchStudio( event ) {
    return this.studiosService.searchStudiosByName(event.query)
  }

  // public goToWizard() {
  //   this.router.navigate(
  //       ['wizard'],
  //       { relativeTo: this.route.parent }
  //   );
  // }

  public changePage(  ) {
    this.studioDataView.first = 0;
  }

  loadPresearchedStudios(searchObject) {
    this.buildAvailabilityOptions();
    const searchParams = Object.entries(searchObject)
    .reduce((acc, [key, val]) => {
      if (val){
        if(key === 'coordinates'){
          acc[`${key}X`] = (val as any)?.x
          acc[`${key}Y`] = (val as any)?.y
        }
        else if(key != 'status') acc[key] = val;     
      }
      return acc;   
    }, {})

    if(searchObject.dateTime){
      const date = new Date(searchObject.dateTime)
      const offset = date.getTimezoneOffset()/60
      date.setHours(date.getHours() - offset)
  
      const timeStr = date.toISOString().split("T")[1].split(":");
      const time = `${timeStr[0]}:${timeStr[1]}`;

      this.bookDate = date;
      this.bookTime = this.availabilityOptions.find(el => el === time)
      this.bookDuration = parseInt(searchObject.duration)
    } 
      
    if(searchObject.service) {
      this.selectedService = searchObject.service
    }

    if(searchObject.country) {
      this.bookLocation = searchObject.country;
      // this.selectedCountry = searchObject.country;
    }


    if (Object.keys(searchParams).length > 0) {
      this.studiosService.searchStudios(searchParams)
          .then((data) => {
            this.helperService.trackSearch(
                {
                  content_name: "STUDIO SEARCH",
                  search_string: JSON.stringify(searchParams)
                }
            );

            this.loading = false;

            this.searchDataView = true;
            this.app.onMenuButtonClick(event);
          });
    }
  }

  passSearchParamsToUrl(searchObject) {
    let searchParams = Object.entries(searchObject)
    .reduce((acc, [key, val]) => {
      if (val){
        if(key === 'coordinates'){
          acc[`${key}X`] = (val as any)?.x
          acc[`${key}Y`] = (val as any)?.y
        }
        else if(key != 'status') acc[key] = val;     
      }
      return acc;   
    }, {}) 
    
    const existingParams = this.route.snapshot.queryParams

    if(this.locationFound === undefined){
      searchParams = {
        ...searchParams,
        coordinatesX: existingParams.coordinatesX,
        coordinatesY: existingParams.coordinatesY,
        country: existingParams.country
      }
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: searchParams, 
        queryParamsHandling: '',
      }
    );
  }

  public mapReady(map) {
    this.gmap = map;
    this.gmap.setCenter({lat: this.mapOptions.center.lat, lng: this.mapOptions.center.lng})
  }

}
