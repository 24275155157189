import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {filter, map} from 'rxjs/operators';
import {HelperService} from '../../services/helper.service';
import {StudiosService} from '../../services/studios.service';
import {BookingsService} from '../../services/bookings.service';
import {ProfileService} from '../../services/profile.service';
import {combineLatest, of} from 'rxjs';
import {UserService} from '../../services/user.service';
import {Location} from '@angular/common';
import {MessageService} from 'primeng/api';
import {PaymentsService} from '../../services/payments.service';
import RevolutCheckout from "@revolut/checkout";

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss']
})
export class BookingSummaryComponent implements OnInit {

    public reviewVisible = false;
    public reviewRating;
    public reviewNote;
    public needReviewAction = false;
    public userReview;

    public summaryType = 'booking';

  public studio: any;
  public env = environment;

  private bookingId;

  private studioId;
  private roomId;
  private rentedInstruments;
  private bookingType;
  public bookingDate;
  public recurringBooking = false;
  public recurringDates;
  public bookingTime;
  public bookingDuration;
  public bookingShortId;
  public serviceType;
  public studioName;
  public studioData;

  public needResponseAction = false;
  public needInviteAction = false;
  public needBookAction = false;
  public needPaymentAction = false;
  public needConfirmAction = false;
  public showPaymentMethods = false;

  public reviewBookingFromEmail = false;

  private bandId;
  public band;
  private selectedMembers;
  public invitedMembers;
  public membersAccepted = [];
  public membersPending = [];
  public soloBand = false;
  public bookingStatus = '';

  public selectedRoom;
  public selectedService;
  public selectedDiscount;
  public selectedPromoCode;
  public selectedInstruments = [];
  public studioNotes;
  public totalPrice = 0;
  public totalRentalPrice = 0;
  public totalServicePrice = 0;

  public bookingOverview;
  public bookingPaymentDone = false;
  // public paymentResponseVisible = false;
  public paymentResponse;

  public paymentFormVisible = false;
  public paymentData: any = {};
  public paymentKeys = [];
  public billingAddress = {
      city : undefined,
      address : undefined ,
      number: undefined ,
      zip : undefined ,
      country : undefined,
  };
  public countries = [];
  public submitted = false;
  public saveBillingInfo = true;
  @ViewChild('paymentSubmit') payFormSubmit: ElementRef;

  private userId;
  public userName;

  constructor(
      private helperService: HelperService,
      private studioService: StudiosService,
      private paymentsService: PaymentsService,
      private profileService: ProfileService,
      private userService: UserService,
      private route: ActivatedRoute,
      private router: Router,
      private location: Location,
      private bookingsService: BookingsService,
      private messageService: MessageService,
  ) {
      this.userId = this.profileService.getUserData().id;
      this.userName = this.profileService.getUserData().name ;
  }

  ngOnInit(): void {
    this.initAddress();

      if (this.route.snapshot.paramMap.keys.length > 0 || this.route.snapshot.queryParamMap.get('requirePayment') === 'true') {
          this.summaryType = 'overview';
          this.bookingId = this.route.snapshot.queryParamMap.get('id');
          if(this.bookingId === null) this.bookingId = this.route.snapshot.paramMap.get('id');

          this.showPaymentMethods = this.route.snapshot.queryParamMap.get('showPaymentMethods') === 'true';
          this.needPaymentAction = this.route.snapshot.queryParamMap.get('requirePayment') === 'true';
          this.reviewBookingFromEmail = this.route.snapshot.queryParamMap.get('reviewBooking') === 'true';
          this.fetchData()
              .then((data) => {

                  if (
                      this.route.snapshot.queryParamMap.has('requirePayment') &&
                      this.route.snapshot.queryParamMap.get('requirePayment') === 'true' &&
                      this.needPaymentAction === true
                  ) {
                      this.showPaymentForm(null);
                  }


              });
      } else {
          this.bookingType = this.route.snapshot.queryParamMap.get('type');
          this.studioId = this.route.snapshot.queryParamMap.get('studio');
          this.roomId = this.route.snapshot.queryParamMap.get('room');
          this.serviceType = this.route.snapshot.queryParamMap.get('service');
          this.rentedInstruments = this.route.snapshot.queryParamMap.getAll('instruments');
          this.bookingDate = this.route.snapshot.queryParamMap.get('date');
          this.bookingDuration = this.route.snapshot.queryParamMap.get('duration');
          this.bookingTime = this.route.snapshot.queryParamMap.get('time');

          this.recurringBooking = this.route.snapshot.queryParamMap.get('recurringBooking') === 'true';
          this.recurringDates = this.route.snapshot.queryParamMap.getAll('recurringDates');


          this.bandId = this.route.snapshot.queryParamMap.get('band');
          this.selectedMembers = this.route.snapshot.queryParamMap.getAll('members');
          this.invitedMembers = [];


          if (this.bookingType === 'band') {
              this.needInviteAction = true;
          }
          this.needBookAction = true;

          this.studio = combineLatest (
              this.studioService.studio$.pipe(filter(std => Object.keys(std).length !== 0)) ,
              this.userService.bands$.pipe(filter(bnd => (Object.keys(bnd).length !== 0) || (this.bookingType === 'solo'))
          )).pipe(
              map((data: any[]) => {
                  this.studioData = data[0];
                  const bandsData = data[1];

                  if (this.bookingType === 'band') {
                      const foundBand = bandsData.find((band) => band.id === this.bandId);
                      this.band = foundBand;
                      this.invitedMembers = foundBand.members
                          .filter((member) => this.selectedMembers.includes(member.user.id ? member.user.id : member.user._id) )
                          .map((member) => { member.member = member.user; return member; });
                  }

                  this.studioData.cover = this.studioData.media.find((img) => img.metadata.type === 'cover');
                  this.studioData.logo = this.studioData.media.find((img) => img.metadata.type === 'logo');
                  this.studioData.images = this.studioData.media.filter((img) => img.metadata.type !== 'logo');
                  this.selectedRoom = this.studioData.rooms.find((room) => room.id === this.roomId);
                  this.selectedService = this.selectedRoom.services.find((service) => service.type === this.serviceType);
                  this.totalServicePrice = this.bookingDuration * this.selectedService.price;
                  if (this.selectedService.discounts && this.selectedService.discounts.length > 0) {


                      let discountToApply;
                      const foundDiscount = this.selectedService.discounts
                          .filter((discount) => discount.available && !discount.isDeleted)
                          .find((discount) => {
                              return !discount.rules.cutoff || discount.rules.cutoff === 0;
                          });

                      const foundPackages = this.selectedService.discounts
                          .filter((discount) => discount.available && !discount.isDeleted)
                          .filter((discount) => {
                              return discount.rules.cutoff && discount.rules.cutoff > 0 && discount.rules.cutoff <= this.bookingDuration;
                          })
                          .sort((a, b) => {
                              return b.rules.cutoff - a.rules.cutoff;
                          });

                      if (foundDiscount) {
                          this.selectedDiscount = foundDiscount;
                      } else if (foundPackages.length > 0) {
                          this.selectedDiscount = foundPackages[0];
                      }
                  }


                  if (this.rentedInstruments && this.rentedInstruments.length > 0) {
                      this.selectedInstruments = this.studioData.instruments
                          .filter((instrument) => this.rentedInstruments.includes(instrument.id));
                      this.selectedInstruments.forEach((rental) => {
                          this.totalRentalPrice = this.totalRentalPrice + rental.price;
                      });
                      this.totalRentalPrice = this.totalRentalPrice * this.bookingDuration;
                  }

                  this.calculateTotalPrice();
                  this.trackInitiateCheckout();
                  return this.studioData;
              })
          );

      }


  }

  trackInitiateCheckout() {
      this.helperService.trackInitiateCheckout(
          {
              content_category: this.serviceType,
              content_ids: [this.studioData.id , this.selectedRoom.id , this.selectedService.id], // Item SKUs
              value: this.totalPrice, // Value of all items
              currency: "EUR", // Currency of the value
          }
      )
  }

  trackAddPaymentInfo() {
      this.helperService.trackAddPaymentInfo(
          {
              content_category: this.serviceType,
              content_ids: [this.studioData.id , this.selectedRoom.id , this.selectedService.id], // Item SKUs
              value: this.totalPrice, // Value of all items
              currency: "EUR", // Currency of the value
          }
      )
  }


  fetchData() {
      return Promise.all([this.bookingsService.fetchBooking(this.bookingId) , this.bookingsService.getReviews(this.bookingId)])
          .then((allData) => {
              const bookingData = allData[0];
              const reviewData = allData[1];
              this.userReview = reviewData.results.find(rev => rev.category === 'user');
              this.bookingType = bookingData.bookingType;
              this.bookingShortId = bookingData.shortId;
              this.studioId = bookingData.studio.id;
              this.studioName = bookingData.studio.name;
              this.studioData = this.studioService.mapStudioData(bookingData.studio);
              this.roomId = bookingData.room.id;
              this.serviceType = bookingData.service.type;
              this.rentedInstruments = bookingData.instruments.map((inst) => inst.id);
              this.bandId = bookingData.team?.id;
              this.band = bookingData.team;
              this.selectedMembers = bookingData.members.map(mbr => mbr.member.id);
              this.invitedMembers = bookingData.members;
              this.membersAccepted = this.invitedMembers.filter(mbr => mbr.vote === 'accept' );
              this.membersPending = this.invitedMembers.filter(mbr => mbr.vote !== 'accept' );

              const bookDate = new Date(bookingData.bookingDate);
              this.bookingDate = bookDate.toString();
              this.bookingDuration = bookingData.duration;
              this.bookingTime = bookDate.toString();

              this.selectedRoom = bookingData.room;
              this.selectedService = bookingData.service;
              this.totalServicePrice = this.totalServicePrice + (this.bookingDuration * this.selectedService.price);
              this.selectedDiscount = bookingData.discount;

              if (this.rentedInstruments && this.rentedInstruments.length > 0) {
                  this.selectedInstruments = bookingData.instruments;
                  this.selectedInstruments.forEach((rental) => {
                      this.totalRentalPrice = this.totalRentalPrice + rental.price;
                  });
                  this.totalRentalPrice = this.bookingDuration * this.totalRentalPrice;
              }

              this.totalPrice = bookingData.totalPrice;
              if ( bookingData.promoCode) {
                  this.selectedPromoCode = bookingData.promoCode;
              }

              if (bookingData.notes.length > 0) {
                  this.studioNotes = bookingData.notes[0].message;
              }

              this.bookingStatus = bookingData.status;

              if (bookingData.recurringDates && bookingData.recurringDates.length > 0) {
                  this.recurringBooking = true;
                  this.recurringDates = [
                      // this.bookingDate ,
                      ...bookingData.recurringDates];
              }

              if ( bookingData.status === 'planning'  ) {
                  const memberUser = bookingData.members.find((member) => member.member.id === this.userId);
                  if (memberUser && memberUser.vote === 'pending') {
                      this.needResponseAction = true;
                  }
              }

              if (
                  bookingData.status === 'planning-complete' &&
                  bookingData.booker.id === this.userId
              ) {
                  this.needConfirmAction = true;
              }

              if (
                  ( bookingData.status === 'pre-send-booking' || bookingData.status === 'pending-confirmation' || bookingData.status === 'studio-confirmed' ) &&
                  bookingData.paymentMethod === 'online' &&
                  (!bookingData.paymentDone || bookingData.paymentDone === false) &&
                  this.helperService.isAfter(new Date(bookingData.bookingDate)) &&
                  bookingData.booker.id === this.userId &&
                  this.env.paymentEnabled &&
                  this.env.paymentEnabled === true
              ) {
                // this.needPaymentAction = true;
                

              }
              this.bookingPaymentDone = (bookingData.paymentDone && bookingData.paymentDone === true);

              if (
                  bookingData.status === 'studio-confirmed' &&
                  !reviewData.results.find(review => review.category === 'user') &&
                  !this.helperService.isAfter(new Date(bookingData.bookingDate)) &&
                  bookingData.booker.id === this.userId &&
                  this.env.reviewEnabled &&
                  this.env.reviewEnabled === true
              ) {
                  this.needReviewAction = true;
              } else {
                  this.needReviewAction = false;
              }
              this.bookingOverview = bookingData;


              this.studio = of(this.studioService.mapStudioData(bookingData.studio));
          });
  }

  calculateTotalPrice() {
      this.totalPrice = 0;

      if (this.selectedDiscount) {
          if (this.selectedDiscount.type === 'percentage') {
              this.totalPrice = this.totalPrice + this.totalServicePrice * ( 100 - this.selectedDiscount.rules.percentage ) / 100;
              this.totalPrice = Math.round(this.totalPrice * 100) / 100;
          } else if (this.selectedDiscount.type === 'amount') {
              this.totalPrice = this.totalServicePrice - this.selectedDiscount.rules.payoff;
          }
      } else {
          this.totalPrice = this.totalPrice + this.totalServicePrice;
      }

      this.totalPrice = this.totalPrice + this.totalRentalPrice;

      if (this.selectedPromoCode) {
          if (  this.selectedPromoCode.category === 'amount' ) {
              this.totalPrice = this.totalPrice - this.selectedPromoCode.discount;
          } else if (this.selectedPromoCode.category === 'percentage') {
              this.totalPrice *= (100 - this.selectedPromoCode.discount) / 100;
              this.totalPrice = Math.round(this.totalPrice * 100) / 100;
          }
      }
      if (this.totalPrice < 0) {
          this.totalPrice = 0;
      }
  }

  openGMaps(studio ) {
    this.helperService.openGMaps('search' , studio.coordinates);
  }

  addPromoCode(event) {
      const promoCodeToAdd = event.promoCode;
      const currentPaymentMethod = event.paymentMethod;
      this.helperService.checkPromoCode(promoCodeToAdd, this.serviceType , this.recurringBooking && this.recurringDates.length > 1 , currentPaymentMethod)
          .then((data) => {
              this.selectedPromoCode = data[0];
              this.calculateTotalPrice();
              this.messageService.add({
                  key: 'globalToast',
                  severity: 'success',
                  summary: 'Valid Promo Code',
                  detail: 'Promo Code added to your order'
              });
          })
          .catch((err) => {
              if (this.selectedPromoCode) {
                  this.selectedPromoCode = undefined;
                  this.calculateTotalPrice();
              }
              this.messageService.add({
                  key: 'globalToast',
                  severity: 'error',
                  summary: 'Invalid Promo Code',
                  detail: err.error.message
              });
          });
  }

  showAddReview(event) {
      this.reviewVisible = true;
  }

    addReview() {
      if ( !this.reviewRating) {
          this.messageService.add({
              key: 'globalToast',
              severity: 'error',
              summary: 'Error',
              detail: 'Please enter a rating!',
              life: 3000
          });
      } else {
          const newReview: any = {
              rating : this.reviewRating
          };
          if (this.reviewNote && this.reviewNote.length > 0) {
              newReview.note = this.reviewNote;
          }
          this.bookingsService.addReview(this.bookingId, newReview)
              .then((data) => {
                  this.reviewVisible = false;
                  this.messageService.add({
                      key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Review Sent', life: 3000});
                  return this.fetchData();
              })
              .catch((err) => {
                  this.messageService.add({
                      key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
                  this.resetReview();
              });
      }
    }



    showPaymentForm(event) {
        this.bookingId = event.bookingId
        // this.fetchData().then(() =>{
        this.paymentFormVisible = true;
        this.submitted = false;
        // })
    }

    payBooking() {
        // this.fetchData()

        this.trackAddPaymentInfo();

        this.submitted = true;
        if (
            this.billingAddress.country &&
            this.billingAddress.zip &&
            this.billingAddress.address &&
            this.billingAddress.number &&
            this.billingAddress.city
        ) {
            // this.paymentData = this.paymentsService.getPaymentForm(this.bookingOverview, this.billingAddress);
            // this.paymentKeys = Object.keys(this.paymentData);

            if (this.saveBillingInfo) {
                const editObject = {
                    address : {
                        street : this.billingAddress.address,
                        number : this.billingAddress.number,
                        zip: this.billingAddress.zip,
                        city: this.billingAddress.city,
                        country: this.helperService.getCountriesName(this.billingAddress.country)
                    }
                };
                this.userService.editUser( this.userId, editObject)
                    .then((data) => {
                        this.profileService.setUserData(data);
                        return Promise.resolve(data)
                    })
                    .then((data) => {
                        return this.paymentsService.initiatePayment(this.bookingId,
                            {
                                billCountry: this.billingAddress.country,
                                billAddress: this.billingAddress.address + ' ' + this.billingAddress.number,
                                billZip: this.billingAddress.zip,
                                billCity: this.billingAddress.city
                            }
                        )
                    })
                    .then((paymentData) => {
                        return this.initRevolutPopup(paymentData);
                    })
            } else {
                this.paymentsService.initiatePayment(this.bookingId ,
                    {
                        billCountry: this.billingAddress.country,
                        billAddress: this.billingAddress.address + ' ' + this.billingAddress.number,
                        billZip: this.billingAddress.zip,
                        billCity : this.billingAddress.city
                    }
                )
                    .then((paymentData) => {
                        return this.initRevolutPopup(paymentData);
                    })
            }

            this.hideDialog();
        }
    }

    hideDialog() {
        this.paymentFormVisible = false;
    }

    previous() {
      if (this.summaryType === 'overview'){
          if (this.needPaymentAction === true || this.reviewBookingFromEmail === true) {
              this.router.navigate(['../'], { relativeTo: this.route });
          } else {
              this.location.back();
          }
      } else {
          this.router.navigate(
              ['room' ],
              {
                  queryParamsHandling: 'merge',
                  relativeTo: this.route.parent,
                  queryParams: {step: 2}
              });
      }
    }

    resetReview() {
      this.reviewVisible = false;
      this.reviewRating = undefined;
      this.reviewNote = '';
    }


    initRevolutPopup(payment) {
        // @ts-ignore
        return RevolutCheckout(payment.token, this.env.revolutMode ? this.env.revolutMode : "sandbox").then((instance) => {
            // work with instance
            const popUp = instance.payWithPopup({
                billingAddress: {
                    countryCode: this.billingAddress.country,
                    streetLine1: this.billingAddress.address + ' ' + this.billingAddress.number,
                    postcode: this.billingAddress.zip,
                    city : this.billingAddress.city
            },
                savePaymentMethodFor: 'customer',

                onSuccess : ()=> {
                    return this.paymentsService.updateRevolutPayment(payment.id , true)
                        .then((data) => {
                            this.bookingsService.editBooking(this.bookingId, {status: "pending-confirmation"})
                            instance.destroy();
                            this.router.navigate(['payment','response'],
                                {
                                    queryParams: {paymentResponse: true , bookingId : this.bookingId }
                                });
                        })
                },
                onError: (error) => {
                    return this.paymentsService.updateRevolutPayment(payment.id , false)
                }
            })
        })


    }

    initAddress() {
        const allCountries = this.helperService.getAvailableCountries();
        this.countries = Object.keys(allCountries).map((countryCode) => {
            return {
                country : allCountries[countryCode],
                code : countryCode
            };
        });

        if ( this.profileService.getUserData().address ) {
            this.billingAddress.zip = this.profileService.getUserData().address.zip ? this.profileService.getUserData().address.zip + '' : this.profileService.getUserData().address.zip;
            this.billingAddress.city = this.profileService.getUserData().address.city;
            this.billingAddress.address = this.profileService.getUserData().address.street;
            this.billingAddress.number = this.profileService.getUserData().address.number;
            this.billingAddress.country = this.helperService.getCountriesCode( this.profileService.getUserData().address.country );
            if (!this.billingAddress.country) {
                this.billingAddress.country = 'GR';
            }
        } else {
            this.billingAddress.country = 'GR';
        }
    }

}
