import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {BandsService} from '../services/bands.service';
import {MessageService} from 'primeng/api';
import {UsersService} from '../services/users.service';
import {environment} from '../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-new-band',
  templateUrl: './new-band.component.html',
  styleUrls: ['./new-band.component.scss']
})
export class NewBandComponent implements OnInit {


  public env = environment;

  public bandLogo: any = 'assets/images/bandbeat/sample_user_logo.png';
  public bandName;
  public bandUsers = [];
  public inviteEmail = '';

  private bandImage;


  constructor(
      private route: ActivatedRoute,
      private router: Router ,
      private location: Location,
      private messageService: MessageService,
      private bandsService: BandsService,
      private usersService: UsersService,
      private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
  }


  checkUser() {
      if (this.inviteEmail.length > 0) {
          if (this.bandUsers.find(user => user.email === this.inviteEmail) ) {
              this.messageService.add({
                  key: 'globalToast',
                  severity: 'error',
                  summary: 'Error',
                  detail: 'User already invited!'
              });
          } else {
              this.usersService.fetchUsers({email : this.inviteEmail})
                  .then((users) => {
                      if (users.results.length > 0 ) {
                          this.bandUsers.push(users.results[0]);
                          this.inviteEmail = '';
                      } else {
                          this.messageService.add(
                              {
                                  key: 'globalToast',
                                  severity: 'error',
                                  summary: 'No such user found!',
                                  detail: 'This email is not used!'
                              });
                      }
                  })
                  .catch((err) => {
                      this.messageService.add(
                          {
                              key: 'globalToast',
                              severity: 'error',
                              summary: 'No such user found!',
                              detail: err.error.message
                          });
                  });
          }
      } else {
          this.messageService.add(
              {
                  key: 'globalToast',
                  severity: 'error',
                  summary: 'Error!',
                  detail: 'You need to enter a valid email!'
              });
      }

  }

    imageUpload(event, type, entity) {
        const file = event.files[0];
        const maxFileSize = 4000000;
        const allowedTypes = ['jpeg', 'jpg', 'png'];

        if (file.size > maxFileSize) {
            this.messageService.add({key: 'globalToast', severity: 'error', summary: 'Error', detail: 'File size exceeds maximum size limit: 4mb!', life: 3000});
            return;
        }

        if (!allowedTypes.includes(this.getExtension(file.name))) {
            this.messageService.add({key: 'globalToast', severity: 'error', summary: 'Error', detail: `File type must be one of the following types: ${allowedTypes}.`, life: 3000});
            return;
        }
        this.bandImage = file;
        this.bandLogo = this.sanitizer.bypassSecurityTrustResourceUrl(event.files[0].objectURL.changingThisBreaksApplicationSecurity);
    }

    getExtension(filename: string): null|string {
        if (filename.indexOf('.') === -1) {
            return null;
        }
        return filename.split('.').pop();
    }

    createBand() {
      if (!this.bandName || this.bandName.trim().length === 0) {
          this.messageService.add({key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Band Name is required!', life: 3000});
      } else {
          const newBand: any = {};
          newBand.name = this.bandName;
          const band: any = {};
          this.bandsService.addBand(newBand)
              .then((data) => {
                  Object.assign(band , data);
                  if (this.bandUsers.length > 0) {
                      let invites = [];
                      invites = this.bandUsers.map((user) => this.bandsService.inviteBandMemberByMail(data.id , user.email));
                      return invites;
                  }
                  return Promise.resolve(data);
              })
              .then((data) => {
                  if (this.bandImage){
                      return this.bandsService.addBandImage(band.id , this.bandImage , 'avatar');
                  } else {
                      return Promise.resolve(data);
                  }
              })
              .then((data) => {
                  this.messageService.add({
                      key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Your Band is created!', life: 3000});

                  this.previousPage();
              });
      }

  }

    public previousPage() {
        this.location.back();
    }

}
