import { Component, OnInit } from '@angular/core';
import {UserService} from '../services/user.service';
import {ProfileService} from '../services/profile.service';
import {filter, map, tap} from 'rxjs/operators';
import {BandsService} from '../services/bands.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {MessageService} from 'primeng/api';
import {InstrumentAndEquipmentService} from '../services/instruments.equipments.service';
import {HelperService} from "../services/helper.service";

@Component({
  selector: 'app-band-profile',
  templateUrl: './band-profile.component.html',
  styleUrls: ['./band-profile.component.scss']
})
export class BandProfileComponent implements OnInit {

  public env = environment;

  private bandId;
  private bandSlug;
  public band;

  public bandName;
  public bandDescription;
  public bandInstagramProfile;
  public bandFacebookProfile;


  public memberDialog = false;
  public memberEmail: string;
  public submitted = false;

  public allowEdit = false;
  public previewMode = false;
  public allowMemberEdit = false;
  public allowMemberRemove = false;
  public userId;

  public musicRolesMap: Map<string, {
    icon: string,
    label: string
  }>;
  public socialModalVisible = false;
  public moreDescription = false;

  constructor(
      private router: Router ,
      private route: ActivatedRoute,
      private messageService: MessageService,
      private userService: UserService,
      private helperService: HelperService,
      private profileService: ProfileService,
      private musicRolesService: InstrumentAndEquipmentService,
      private bandsService: BandsService
  ) {
    this.userId = this.profileService.getUserData().id;
    this.bandSlug = this.route.snapshot.paramMap.get('bandSlug');
    this.band = this.bandsService.band$.pipe(
        filter((data: any) => Object.keys(data).length !== 0),
        map((data: any) => {
          this.bandId = data.id;
          if (data.media && data.media.length > 0) {
            data.avatar = data.media.reverse().find((media => media.metadata.type === 'avatar'));
          }
          if (data.members && data.members.length > 0) {
            data.members.forEach((mbr) => {
              mbr.user.musicRoles = mbr.user.musicRoles.map((musicRole) => {
                musicRole.categoryLabel = this.musicRolesMap.get(musicRole.type).label;
                musicRole.categoryIcon = this.musicRolesMap.get(musicRole.type).icon;

                return musicRole;
              })
            })
          }
          return data;
        }),
        tap((data) => {
          if (data.members.length > 0) {
            const foundMember = data.members.find((mbr) => mbr.user.id === this.userId);
            if (!foundMember) {
              this.previewMode = true;

              data.members.forEach((mbr) => {
                mbr.user.musicRoles = mbr.user.musicRoles.filter((musicRole) => {
                  return musicRole._id ? musicRole._id === mbr.musicRole : musicRole.id === mbr.musicRole
                })
              })
            } else {
              if (foundMember.role === 'admin') {
                this.allowMemberRemove = true;
              }
            }
            this.bandName = data.name;
            this.bandDescription = data.description;
            this.bandInstagramProfile = data.instagramHandle;
            this.bandFacebookProfile = data.facebookHandle;

          }
        })
    );

    this.bandsService.fetchBand(this.bandSlug, true);
  }

  ngOnInit(): void {
    this.musicRolesMap = this.musicRolesService.getMusicRolesMap();
  }


  refresh() {
    this.bandsService.fetchBand(this.bandId, true);
  }

  removeMember(member) {
    this.bandsService.removeMember(this.bandId , member.user.id).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Member Removed', life: 3000});
    });
  }

  seeMember(member) {
    this.router.navigate([ 'user' , member.user.id ] ,
        {
          relativeTo: this.route.parent.parent,
        });
  }

  inviteMember() {
    if (this.memberEmail.trim().length > 0) {
      this.submitted = true;
      this.helperService.checkUserExists(this.memberEmail)
          .then((data :any) => {
            console.log(data);
            if (data.exists === true) {
              return this.bandsService.inviteBandMemberByMail(this.bandId , this.memberEmail)
            } else {
              return this.helperService.inviteUserToBandBeat(this.profileService.getUserData().name , this.memberEmail, this.bandId)
            }
          })
          .then((data) => {
            this.messageService.add({
              key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Invite Sent', life: 3000});
            this.memberEmail = '';
            this.memberDialog = false;
            this.bandsService.fetchBandInvites(this.bandId);
          })
          .catch((err) => {
            this.messageService.add({
              key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
            this.memberEmail = '';
            this.memberDialog = false;
          });

    }
  }

  editBand() {
    this.bandsService.editBand(this.bandId ,
        {
          name: this.bandName ,
          description: this.bandDescription ,
          facebookHandle: this.bandFacebookProfile ,
          instagramHandle: this.bandInstagramProfile
        }
    ).then((data) => {
      this.messageService.add({
        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Changes Saved', life: 3000});
    });
  }

  setMusicRoleAsPrimary(musicRole: any, member: any) {
    if (
        this.allowMemberEdit &&
        member.user.id === this.userId &&
        (!member.musicRole || member.musicRole !== musicRole._id)
    ) {
      this.bandsService.editBandMusicRole(this.bandId, member.user.id, musicRole._id)
          .then((result) => {
            this.messageService.add({
              key: 'globalToast',
              severity: 'success',
              summary: 'Successful',
              detail: 'Primary Instrument Selected',
              life: 3000
            });
          });
    }
  }

  openNew() {
    this.memberEmail = '';
    this.submitted = false;
    this.memberDialog = true;
  }

  hideDialog() {
    this.memberEmail = '';
    this.memberDialog = false;
    this.submitted = false;
  }

  imageUpload(event) {
    this.bandsService.addBandImage(this.bandId, event.files[0], 'avatar')
        .then((data) => {
          this.messageService.add({
            key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Image Uploaded', life: 3000
          });
        })
        .catch((err) => {
          const error = err.error;
          if (error.code === 500 && error.message === 'File too large') {
            this.messageService.add({
              key: 'globalToast',
              severity: 'error',
              summary: 'Error',
              detail: 'File is over 4mb!',
              life: 3000
            });
          }
        });
  }


  shareClicked(buttonClicked: string, socialHande?: string) {
    if (buttonClicked === 'facebook') {
      window.open('https://www.facebook.com/' + socialHande, '_blank').focus();
    } else if (buttonClicked === 'instagram') {
      window.open('https://www.instagram.com/' + socialHande, '_blank').focus();
    }
  }

  closeModal(displayState) {
    this.socialModalVisible = false;
  }

}
