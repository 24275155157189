<p-card
        header="Booking Overview">
    <p style="
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    ">
        <span  class="tn-text-simple">
            Studio Booking ({{selectedService.type | titlecase}})
        </span>
        <span class="tn-text-big tn-text-green">
            {{servicePrice}}{{getCurrency()}}
        </span>
    </p>

    <p

            *ngIf="selectedDiscount"
            style="font-size: 0.8rem; font-style: italic;"
    ><span class="tn-text-simple">Applied Discount ({{selectedDiscount.name | titlecase}})</span>
        <span class="tn-text-big" style="font-style:initial; float:right;" *ngIf="selectedDiscount.type === 'percentage'">
        - {{selectedDiscount.rules.percentage}} %
        </span>
        <span class="tn-text-big" style="font-style:initial; float:right;" *ngIf="selectedDiscount.type === 'amount'">
        - {{selectedDiscount.rules.payoff}} {{getCurrency()}}
        </span>
    </p>


    <p style="
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    "
    >
        <span class="tn-text-simple">Instrument Rental </span>
        <span class="tn-text-green tn-text-big">
        {{rentalPrice}}{{getCurrency()}}
        </span>
    </p>

    <ng-template
            [ngIf]="env.promoCodesEnabled && promoCode"
    >
        <p class="tn-text-big" style="
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    ">
            <span style="font-style: italic;">{{promoCode.name}}</span>
            <span class="tn-text-green" > - {{promoCode.discount}} {{promoCode.category === 'amount' ? getCurrency(currency) : '%' }}</span>
        </p>
    </ng-template>

    <p-divider></p-divider>
    <p class="tn-text-simple" style="
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    "><span><strong>Total Cost {{recurringDates.length > 1 ? ' (per day) ' : ''}}</strong></span>
        <span class="tn-text-green tn-text-big" style="
font-size: 1.3125rem;
font-style: normal;
font-weight: 700;
line-height: 1.3125rem; /* 100% */
letter-spacing: 0.02625rem;"><strong>
        {{totalPrice}}{{getCurrency()}}
        </strong></span></p>
    <p class="tn-text-big" style="
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    "
        *ngIf="recurringDates.length > 1"
    ><span><strong>Total Cost for {{recurringDates.length}} days</strong></span>
        <span class="tn-text-green tn-text-big" style="float:right;"><strong>
        {{totalPrice * recurringDates.length}}{{getCurrency()}}
        </strong></span></p>
    <p class="tn-text-big"
    *ngIf="
        bookingOverview &&
        bookingOverview.status !== 'planning' &&
        bookingOverview.paymentMethod
    ">
        <strong *ngIf="
        bookingOverview.paymentMethod === 'online' &&
        bookingOverview.paymentDone === true &&
        bookingOverview.paymentStatus &&
        bookingOverview.paymentStatus === 'CANCELED'
        ">
            Payment<span
                class="keyword"
                style="float:right;"
        >Canceled</span>
        </strong>
        <strong *ngIf="
        bookingOverview.paymentMethod === 'online' &&
        bookingOverview.paymentStatus &&
        bookingOverview.paymentStatus === 'AUTHORIZED'
        ">
            Payment<span
                class="keyword"
                style="float:right;"
                pTooltip="User has paid"
        >Paid</span>
        </strong>
        <strong *ngIf="
        bookingOverview.paymentMethod === 'online' &&
        bookingOverview.paymentDone === true &&
        bookingOverview.paymentStatus &&
        bookingOverview.paymentStatus === 'CAPTURED'
        ">
                Payment<span
                class="keyword"
                style="float:right;"
                [pTooltip]="!bookingOverview.reported ? 'User has paid' : 'User will receive back 0% of paid ammount. No Refund (please read our cancellation policy)'"
        >Paid</span>
        </strong>
        <strong *ngIf="
        bookingOverview.paymentMethod === 'online' &&
        bookingOverview.paymentDone === true &&
        bookingOverview.paymentStatus &&
        bookingOverview.paymentStatus === 'REFUNDED'
        ">
            Payment<span
                class="keyword"
                style="float:right;"
                [pTooltip]="'User will receive back percentage of paid ammount (please read our cancellation policy).'"
        >Refunded</span>
        </strong>
        <strong *ngIf="bookingOverview.paymentMethod === 'offline' || bookingOverview.paymentDone !== true">
            Payment<span class="" style="float:right;">At the Studio</span>
        </strong>

    </p>
    <ng-template pTemplate="footer">


        <div *ngIf="needBookAction && bookingType === 'band' && !soloBand">

<!--            <p class="tn-text-green tn-text-big" >-->
<!--                Booking Type-->
<!--            </p>-->

            <div class="inline-block ">
                <div class="pt-4 flex align-items-center"  *ngFor="let requestOption of requestVoteOptions; let requestOptionIndex">
                    <p-radioButton
                            name="paymentMethod"
                            [value]="requestOption.value"
                            [(ngModel)]="requestVote"
                            (ngModelChange)="changePaymentMethod($event)"
                            inputId="requestMethod{{requestOptionIndex}}"
                    ></p-radioButton>
                    <p
                            [ngClass]="{'tn-text-big' : requestVote === requestOption.value}"
                            class="ml-2"
                    >{{requestOption.label}}</p>
                </div>
            </div>
        </div>

        <div
            *ngIf="env.paymentEnabled &&
                   (needConfirmAction || needBookAction || showPaymentMethods)
            "
            [style]="{
            width: '100%',
            'margin-bottom': '1em',
            display: 'inline-block' ,
            'align-items' : 'center'
            }"
            class="pt-4"
        >

            <p class="tn-text-green tn-text-big" >
                Payment
            </p>

            <div class="flex flex-wrap gap-8">
                <div class="flex align-items-center"  *ngFor="let paymentMethod of paymentOptions; let paymentIndex">
                    <p-radioButton
                            name="paymentMethod"
                            [value]="paymentMethod.value"
                            [(ngModel)]="selectedPayment"
                            [disabled]="paymentMethod.disabled"
                            (ngModelChange)="changePaymentMethod($event)"
                            inputId="paymentMethod{{paymentIndex}}"
                    ></p-radioButton>
                    <p
                            [ngStyle]="{'font-weight' : selectedPayment === paymentMethod.value ? '700' : '400' }"

                            class="ml-2 tn-text-big"
                    >{{paymentMethod.label}}</p>
                </div>
            </div>

        </div>


        <div
                class="pt-4"
                *ngIf=" env.promoCodesEnabled &&  needBookAction "
                [style]="{
                        width: '100%',
                        'margin-bottom': '2em',
                        display: 'inline-block' ,
                        'align-items' : 'center'
                        }"
        >

            <p class="tn-text-green tn-text-big" >
                Promo Code
            </p>
            <ng-template
                    [ngIf]="!promoCode"
            >
                <div class="grid grid-nogutter">
                    <div class="col-12 md:col-8">
                        <input inputTrim
                               [(ngModel)]="promoCodePromo"
                               type="text"
                               pInputText
                               style="width:100%"
                               placeholder="Enter your Promo Code"
                        />
                    </div>
                    <div class="col-12 md:col-4">
                        <button
                                [disabled]="!promoCodePromo || promoCodePromo.length === 0"
                                type="button"
                                pButton
                                icon="pi pi-check"
                                class="  tn-secondary-button promo-submit-btn md:ml-2"
                                label="SUBMIT"
                                (click)="checkPromoCode()"
                        ></button>
                    </div>
                </div>
            </ng-template>
            <ng-template
                    [ngIf]="promoCode"
            >
                <p class="tn-text-simple" >
                    Promo Code Applied
                </p>
            </ng-template>
        </div>

        <div *ngIf="needBookAction || needConfirmAction">

            <p-button
                    (onClick)="needConfirmAction ? sendToStudio() : confirmBooking()"
                    [style]="{width: '100%'}"
                    icon="till-noon-icon tnicon-Wave"
                    styleClass="p-button-rounded tn-secondary-button"
                    label="SEND REQUEST"
                    [label]="'SEND REQUEST' + ( selectedPayment === 'card' ? ' & PAY ONLINE' : '')"
            ></p-button>

        </div>

        <div *ngIf="needResponseAction">
            <button
                    pButton
                    pRipple
                    (click)="responseInvite(true)"
                    [style]="{width: '100%'}"
                    icon="pi pi-check"
                    class="p-button-rounded tn-secondary-button"
                    label="ACCEPT REQUEST"></button>
            <button
                    class="p-button-rounded tn-danger-button mt-3"
                    label="DECLINE REQUEST"
                    pButton
                    pRipple
                    type="button"
                    [style]="{width: '100%'}"
                    [icon]="'pi pi-times'"
                    (click)="responseInvite(false)"
            ></button>

        </div>

        <div *ngIf="needReviewAction">
            <p-button
                    (onClick)="showAddReview()"
                    [style]="{width: '100%'}"
                    icon="till-noon-icon tnicon-Wave"
                    styleClass="p-button-rounded tn-secondary-button"
                    label="ADD REVIEW"
            ></p-button>

        </div>

        <div *ngIf="needPaymentAction && env.paymentEnabled">
            <p-button
                    (onClick)="(showPaymentMethods && selectedPayment==='cash')? sendToStudio() : showPayBooking()"
                    [style]="{width: '100%'}"
                    icon="till-noon-icon tnicon-Wave"
                    styleClass="p-button-rounded tn-secondary-button"
                    label="PAY BOOKING"
            ></p-button>
        </div>


        <div style="font-style:italic; text-align: center; padding-top: 2rem;" >

            <a href="/terms&conditions?scrollTo=userCancelation" target="_blank">
                <span class="tn-text-green tn-text-xs" style="font-style: italic">CANCELLATION POLICY</span>
            </a>


        </div>
    </ng-template>

</p-card>

