<div class="grid grid-nogutter pt-4" *ngIf="userBands | async as bands">
    <div class="col-12 md:col-6">
<!--        <h2>Request to book the studio as:</h2>-->
        <p class="tn-title" style="font-weight: 400;">Request to book the studio as:</p>

        <div class="grid">

            <div class="col-6 md:col-3  md:pt-5" style="text-align: center;">

                <div
                        class="booking-type"
                        (click)="bookingType = 'solo';checkNext();"
                        [ngClass]="{'selected' : bookingType === 'solo'}"
                >
                    <img
                        [src]="'assets/images/solo_booking.png'"
                        alt="solo artist studio booking"
                    />
                    <h2>Solo</h2>
                </div>
            </div>
            <div class="col-6 md:col-3 md:pt-5" style="text-align: center;">
                <div
                        class="booking-type "
                        (click)="bookingType = 'band';checkNext();"
                        [ngClass]="{'selected' : bookingType === 'band'}"
                >
                    <img
                        [src]="'assets/images/band_booking.png'"
                        alt="band studio booking"
                    />
                    <h2>Band</h2>
                </div>
            </div>
            <div class="md:col-6"></div>
            <div class="col-3 md:col-3"></div>
            <div class="md:col-9" *ngIf="bands.length == 0 && bookingType == 'band'">
                <p-button
                icon="pi pi-plus"
                label="Create Band"
                styleClass="p-button-primary tn-secondary-button"
                class="create-band-btn"
                (click)="createBand()"
                ></p-button>
            </div>
            
        </div>


    </div>

    <div class="col-12 md:col-6 pt-4 md:pt-0" *ngIf="studio | async as studioData">
<!--        <h2>Choose service</h2>-->
        <p class="tn-title" style="font-weight: 400;">Choose service</p>

        <div class="grid ">

            <div class="col-12 pt-5"  *ngFor="let serviceOption of availableServices;">

                <div
                        class="service-type"
                        [ngClass]="{'selected' : serviceType === serviceOption.code}"
                        (click)="serviceType = serviceOption.code; checkNext();"
                >
                    <i class="till-noon-icon tnicon-{{serviceOption.name}}"></i>
                    <span class="service-text ml-3">{{serviceOption.name}}</span>
                </div>


            </div>
        </div>



    </div>


    <!-- <div class="col-12 md:col-6 justify-content-start" *ngIf="bookingType == 'band'">
                <p-button
                icon="pi pi-plus"
                label="Create Band"
                styleClass="p-button-primary tn-secondary-button"
                class="create-band-btn"
                ></p-button>
    </div> -->


    <div class="col-12 pt-4 md:pt-7 booking-margin-bot" *ngIf="bands.length > 0 && bookingType === 'band'">
<!--        <h2>Select Band</h2>-->
        <p class="tn-title " style="font-weight: 400;">Select Band</p>
        <p-dataView #dvbands
                    [value]="bands"
                    class="band-dv"
                    layout="grid">
            <ng-template let-band pTemplate="gridItem">
                <div class="col-12 md:col-4" style="padding: 1rem 1rem 0  0;">

                    <app-tn-band-simple
                        [band]=band
                        [selected]="selectedBand ? selectedBand.id === band.id : false"
                        (bandSelectEmitter)="selectBand($event)"
                    >
                    </app-tn-band-simple>
                </div>
            </ng-template>
        </p-dataView>


<!--        <div class="flex align-items-center" style="float:right" >-->
<!--            <button-->
<!--                    pButton-->
<!--                    label="Create New Band"-->
<!--                    type="button"-->
<!--                    icon="pi pi-plus"-->
<!--                    iconPos="left"-->
<!--                    class="p-button-rounded p-button-text"-->
<!--                    (click)="createBand()"-->
<!--            ></button>-->
<!--        </div>-->
    </div>




    <div class="col-12  pt-4 md:pt-7 booking-margin-bot" *ngIf="bands.length > 0 && bookingType === 'band' && selectedBand && selectedBand.members.length > 0">

<!--            <h5>Select Bandmates</h5>-->
            <p class="tn-title " style="font-weight: 400;">Select Bandmates</p>
        <p-dataView #dvmembers
                    [value]="selectedBand.members"
                    class="band-dv"
                    layout="grid">
            <ng-template let-member pTemplate="gridItem">
                <div class="col-12 md:col-4" style="padding: 1rem 1rem 0  0;">

                    <app-tn-band-member
                            [member]=member
                            [selected]="selectedMembers.includes(member)"
                            (memberSelectEmitter)="selectBandMember($event)"
                    >
                    </app-tn-band-member>
                </div>
            </ng-template>
        </p-dataView>
    </div>




</div>



<div class="grid grid-nogutter next-buttons">
    <p-button
            disabled="true"
            label="Previous"
            styleClass="p-button-primary tn-primary-button"
            icon="till-noon-icon tnicon-Arrow-Left"
            [ngStyle]="{visibility:'hidden'}"
            iconPos="left"
    ></p-button>
    <p-button
            [disabled]="!availableNext"
            label="Next"
            (onClick)="nextPage()"
            icon="till-noon-icon tnicon-Arrow-Right"
            iconPos="right"
            styleClass="tn-secondary-button"
    ></p-button>
</div>
