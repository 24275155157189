<div class="grid bandbeatPage" *ngIf="band | async as bandData">

    <div class="col-12">
        <div class="grid">
            <div class="col-12 md:col-5">

                <div class="grid">
                    <div class="col-fixed" style="width: 14rem">


                        <p-avatar
                                *ngIf="previewMode"
                                shape="circle"
                                class="top-avatar"
                                [image]="bandData.hasOwnProperty('avatar') && bandData.avatar ? bandData.avatar.link : 'assets/images/bandbeat/sample_user_logo.png'"
                        >
                        </p-avatar>

                        <ng-template
                                [ngIf]="!previewMode"
                        >


                            <p-avatar
                                    shape="circle"
                                    [image]="bandData.hasOwnProperty('avatar') && bandData.avatar ? bandData.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                            >
                            </p-avatar>
                            <button pButton
                                    id="logoButton"
                                    pRipple type="button"
                                    (click)="logoUpload.choose()"
                                    icon="till-noon-icon tnicon-Pencil"
                                    class="p-button-rounded p-button-icon-only p-button-text tn-primary-button  uploadButton">
                            </button>

                            <p-fileUpload
                                #logoUpload
                                class="logoUploader"
                                [customUpload]="true"
                                [auto]="true"
                                accept="image/*"
                                (uploadHandler)="imageUpload($event)"
                            ></p-fileUpload>

                        </ng-template>
                    </div>


                    <div class="col">
                        <p class="tn-title" style="margin-bottom:0">
                            {{bandData.name}}
                        </p>
                        <span class="tn-text-small tn-text-green"  >
                            Jamming on Bandbeat since {{bandData.createdAt | dateParser:'MMMM yyyy'}}
                        </span>


                        <ul class="socialList  pt-2 md:pt-4">
                            <li>
                                <button
                                        icon="till-noon-icon tnicon-Facebook"
                                        pButton
                                        type="button"
                                        [disabled]="!(bandData.facebookHandle && bandData.facebookHandle.length >0)"
                                        (click)="shareClicked('facebook' , bandData.facebookHandle)"
                                        class="p-button-text tn-primary-button socialButton"
                                ></button>
                            </li>
                            <li>
                                <button
                                        icon="till-noon-icon tnicon-Instagram"
                                        pButton
                                        type="button"
                                        [disabled]="!(bandData.instagramHandle && bandData.instagramHandle.length >0)"
                                        (click)="shareClicked('instagram', bandData.instagramHandle)"
                                        class="p-button-text tn-primary-button socialButton"
                                ></button>
                            </li>
                            <li>
                                <button
                                        icon="pi pi-share-alt"
                                        pButton
                                        type="button"
                                        label="Share Profile"
                                        class="p-button-text tn-primary-button"
                                        (click)="socialModalVisible = !socialModalVisible"
                                ></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="hidden md:block md:col-7">
            </div>
        </div>

    </div>


    <div class="col-12 md:col-4">

        <p class="tn-title" style="padding-bottom:2rem;font-weight:400;">Band Info</p>


        <ul class="profile-elements" >
            <li *ngIf="!previewMode">
                <p class="tn-text-simple tn-text-green ">BAND NAME</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{bandData.name}}</p>
                <input
                        *ngIf="allowEdit"
                        id="userFname"
                        inputId="userFname"
                        type="text"
                        pInputText
                        [(ngModel)]="bandName"
                />
            </li>
            <li *ngIf="!previewMode">
                <p class="tn-text-simple tn-text-green">INSTAGRAM PROFILE</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{bandData.instagramHandle}}</p>
                <div class="p-inputgroup"
                     *ngIf="allowEdit"
                >
                    <span class="p-inputgroup-addon">https://www.instagram.com/</span>

                    <input
                            inputTrim
                            id="userInstagramProfile"
                            inputId="userInstagramProfile"
                            type="text"
                            pInputText
                            [(ngModel)]="bandInstagramProfile"
                    />
                </div>
            </li>
            <li *ngIf="!previewMode">
                <p class="tn-text-simple tn-text-green">FACEBOOK PROFILE</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{bandData.facebookHandle}}</p>
                <div class="p-inputgroup"
                     *ngIf="allowEdit"
                >
                    <span class="p-inputgroup-addon">https://www.facebook.com/</span>
                    <input
                            inputTrim
                            id="userFacebookProfile"
                            inputId="userFacebookProfile"
                            type="text"
                            pInputText
                            [(ngModel)]="bandFacebookProfile"
                    />
                </div>
            </li>
            <li>
                <p class="tn-text-simple tn-text-green">ABOUT</p>
                <p *ngIf="!allowEdit" class="tn-text-simple">{{bandData.description | readMore:moreDescription:300}}</p>
                <div
                        class="flex justify-content-center md:justify-content-end pt-2"
                        *ngIf="!allowEdit && bandData.description && bandData.description.length > 300"
                >
                    <a (click)="moreDescription = !moreDescription"  class="tn-text-green tn-text-xs cursor-pointer">
                        <span *ngIf="!moreDescription">READ MORE</span>
                        <span *ngIf="moreDescription">READ LESS</span>
                        <i style="padding-left: 1rem;"
                           [ngClass]="{'pi-chevron-down' : !moreDescription , 'pi-chevron-up' : moreDescription}"
                           class="pi tn-text-xs"></i>
                    </a>
                </div>
                <textarea
                        *ngIf="allowEdit"
                        [(ngModel)]="bandDescription"
                        [rows]="5"
                        [cols]="30"
                        style="width:100%"
                        pInputTextarea
                        [autoResize]="true"
                ></textarea>
            </li>
            <li>
                <button
                        *ngIf="!allowEdit && !previewMode"
                        icon="till-noon-icon tnicon-Pencil"
                        pButton
                        label="EDIT"
                        type="button"
                        class=" tn-primary-button "
                        (click)="allowEdit = !allowEdit"
                ></button>
                <button
                        *ngIf="allowEdit"
                        icon="pi pi-check"
                        pButton
                        label="SAVE"
                        type="button"
                        class=" tn-secondary-button "
                        (click)="editBand();allowEdit = !allowEdit;"
                ></button>
            </li>
        </ul>
    </div>



    <div class="col-12 md:col-8">

        <div class="grid ">

            <div class="col-12" *ngIf="!previewMode">
                <button
                        icon="pi pi-plus"
                        pButton
                        label="INVITE YOUR FRIENDS ON BAND"
                        type="button"
                        style="float:right"
                        class=" tn-primary-button  "
                        (click)="openNew()"
                ></button>
            </div>

            <div class="col-12">

                <p-card
                        class="members-card"
                        header="Members">

                    <p class="tn-text-small tn-text-green" >
                        Here you can add all your bandmates and the main instrument they play in this band! You may set each of your mates main instrument by selecting it out of the ones available based on their profile.
                    </p>


                    <p-dataView #dvListingEq
                                [value]="bandData.members"
                                styleClass="members-dv"
                                layout="list">
                        <ng-template let-bandMember pTemplate="listItem">
                            <div class="col-12">
                                <div class="equipment-list-item"
                                >



                                    <p-card
                                            styleClass="p-card-shadow  grey-mode band-card"

                                    >

                                        <div class="grid grid-nogutter align-items-center" >
                                            <div
                                                    class="col-fixed member-cover"
                                            >
                                                <p-avatar
                                                        [image]="bandMember.user.avatar ? bandMember.user.avatar['link'] : 'assets/images/sample_member.png'"
                                                        shape="circle" styleClass="mr-2 band-avatar" ></p-avatar>
                                            </div>
                                            <div
                                                    style="height: auto;"
                                                    class="ml-4 col-fixed member-name"
                                            >
                                                <p style="margin:auto" class="tn-text-big">{{bandMember.user.name}}</p>
                                            </div>

                                            <div class="col-12 md:col md:ml-4"
                                            >




                                                <p-dataView #dvListingEq
                                                            [value]="bandMember.user.musicRoles"
                                                            styleClass="equipment-dv"
                                                            layout="grid">
                                                    <ng-template let-musicRole pTemplate="gridItem">
                                                        <div class="col-6 md:col-6 lg:col-3">
                                                            <div
                                                                    [ngClass]="{'clickable-card' : allowMemberEdit}"
                                                                    class="equipment-list-item"
                                                                    (click)="setMusicRoleAsPrimary(musicRole, bandMember)">
                                                                <div class="grid  grid-nogutter md:align-items-center" style="margin-bottom:-1rem; width: 100%;" >
                                                                    <div class=" col-4">
                                                                        <i class="md:inline-block hidden circular-icon till-noon-icon {{musicRole.categoryIcon}}"></i>
                                                                        <i class="md:hidden circular-icon circular-icon till-noon-icon {{musicRole.categoryIcon}}"></i>

                                                                        <button pButton
                                                                                *ngIf="musicRole._id ? musicRole._id === bandMember.musicRole : musicRole.id === bandMember.musicRole "
                                                                                pRipple type="button" icon="pi pi-star"
                                                                                class="p-button-rounded p-button-icon-only primary-music-role">
                                                                        </button>


                                                                    </div>
                                                                    <div class="col-8 pl-0">
                                                                        <p class="tn-text-simple">{{musicRole.categoryLabel | titleCaseExtendedPipe}}</p>


                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dataView>

                                            </div>

                                            <div class="col-fixed"
                                                 *ngIf="allowMemberEdit && allowMemberRemove">
                                                <div
                                                        class="flex justify-content-end"
                                                >

                                                    <button
                                                            icon="pi pi-times"
                                                            pButton
                                                            label="DELETE"
                                                            type="button"
                                                            class=" tn-danger-button "
                                                            (click)="removeMember(bandMember)"
                                                    ></button>
                                                </div>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template [ngIf]="!previewMode">
                            <ng-template
                                    pTemplate="footer"
                                    style="padding:0;">
                                <div class="flex justify-content-start">
                                    <button
                                            *ngIf="!allowMemberEdit"
                                            icon="till-noon-icon tnicon-Pencil"
                                            pButton
                                            label="EDIT"
                                            type="button"
                                            class=" tn-primary-button "
                                            (click)="allowMemberEdit = !allowMemberEdit">
                                    </button>
                                    <button
                                            *ngIf="allowMemberEdit"
                                            icon="pi pi-check"
                                            pButton
                                            label="SAVE"
                                            type="button"
                                            class=" tn-secondary-button "
                                            (click)="allowMemberEdit = !allowMemberEdit"
                                    ></button>
                                </div>
                            </ng-template>
                        </ng-template>


                    </p-dataView>

                </p-card>

            </div>



        </div>

<!--        -->
<!--        <h2 style="margin-bottom:0">Members</h2>-->
<!--        <div class = "col-12 lg:col-6 grid p-nogutter" style="font-size: 11px; font-weight: 400; margin-bottom:1.5rem" >-->
<!--            Here you can add all your bandmates and the main instrument they play in this band! You may set each of your mates main instrument by selecting it out of the ones available based on their profile.-->
<!--        </div>-->

<!--        <div class="flex align-items-center music-roles" *ngFor="let bandMember of bandData.members" style="margin-bottom: 24px;">-->

<!--            <p-chip-->
<!--                    [label]="bandMember.user.name"-->
<!--                    [image]="bandMember.user.hasOwnProperty('avatar') ? env.uploadUrl + bandMember.user.avatar.link : 'assets/images/bandbeat/sample_user_logo.png'"-->

<!--                    styleClass="custom-chip mr-4"-->
<!--                    class="custom-chip"-->
<!--                    icon="pi pi-check"-->
<!--                    [removable]="allowEdit"-->
<!--                    (onRemove)="removeMember(bandMember)"-->
<!--            ></p-chip>-->

<!--            <ng-template ngFor let-userInstrument let-i="index" [ngForOf]="bandMember.user.instruments">-->
<!--                <p-chip-->
<!--                        [label]="userInstrument.instrument.name"-->
<!--                        image="assets/demo/images/avatar/xuxuefeng.png"-->
<!--                        styleClass="custom-chip mr-4"-->
<!--                        class="custom-chip"-->
<!--                        icon="pi pi-check"-->
<!--                ></p-chip>-->
<!--            </ng-template>-->

<!--            <ng-template ngFor let-musicRole let-i="index" [ngForOf]="bandMember.user.musicRoles">-->
<!--                <p-chip-->
<!--                        [label]="musicRolesMap.get(musicRole.type)?.label"-->
<!--                        (click)="setMusicRoleAsPrimary(musicRole, bandMember)"-->
<!--                        [ngClass]="{'primary-music-role':bandMember.musicRole === musicRole._id, 'editable' : bandMember.user.id === userId}"-->
<!--                        styleClass="custom-chip mr-4 primary"-->
<!--                        class="custom-chip"-->
<!--                        icon="{{musicRolesMap.get(musicRole.type)?.icon}}"-->
<!--                ></p-chip>-->
<!--            </ng-template>-->
<!--        </div>-->

<!--        <div-->
<!--                class="flex align-items-center"-->
<!--                *ngIf="allowEdit"-->
<!--        >-->
<!--            <button-->
<!--                    pButton-->
<!--                    label="Invite new member"-->
<!--                    type="button"-->
<!--                    icon="pi pi-plus"-->
<!--                    iconPos="left"-->
<!--                    class="p-button-rounded p-button-text"-->
<!--                    (click)="openNew()"-->
<!--            ></button>-->
<!--        </div>-->

    </div>

    <p-dialog [(visible)]="memberDialog" [style]="{width: '450px'}"[modal]="true" styleClass="p-fluid">

        <ng-template pTemplate="header">
            <p class="tn-text-big tn-text-green">Invite New Member</p>
        </ng-template>
        <ng-template pTemplate="content">
<!--            <img-->
<!--                    [src]="bandData.hasOwnProperty('avatar') && bandData.avatar ? env.uploadUrl + bandData.avatar.link : 'assets/images/bandbeat/sample_user_logo.png'"-->
<!--                    style="max-width:100%;height:30em;margin:auto;display:block;"-->
<!--                    alt="new member"-->
<!--                    class="product-image"-->
<!--            >-->
            <div class="flex align-items-center">
                <p-avatar
                        *ngIf="!allowEdit"
                        shape="circle"
                        styleClass="inviteAvatar"
                        [image]="bandData.hasOwnProperty('avatar') && bandData.avatar ? bandData.avatar['link'] : 'assets/images/bandbeat/sample_user_logo.png'"
                >
                </p-avatar>

                <div class="pl-5">
                    <p class="tn-text-big">
                        {{bandName}}
                    </p>
                    <span class="tn-text-simple">Ready to grow your Band?</span>
                </div>
            </div>


            <div class="field pt-4">
                <label for="name">Email</label>
                <input inputTrim type="text" pInputText id="name" [(ngModel)]="memberEmail" required autofocus />
                <small class="p-invalid" *ngIf="submitted && !memberEmail">Email is required.</small>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="tn-danger-button" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="tn-secondary-button" (click)="inviteMember()"></button>
        </ng-template>
    </p-dialog>



</div>






















<app-social-share
        [display]="socialModalVisible"
        (closeModalEmitter)="closeModal($event)"
></app-social-share>





