<p class="tn-title"  style="font-weight:400;">Past Payments</p>

<div class="grid">

    <div class="col-12">
        <span class="tn-text-green tn-text-small">All account settings are private.</span>
    </div>

    <div class="col-12">
        <p-dataView #dvpayments
                    [value]=" (payments | async) | filterPayments: ['CAPTURED' , 'REFUNDED'] "
                    [paginator]="true"
                    [rows]="20"
                    [loading]="loading"
                    layout="list">
            <ng-template let-payment pTemplate="listItem">
                <div class="col-12 payment-item cursor-pointer" [routerLink]="[ '..', 'bookings', payment.booking.id]">

                    <div class="product-list-item grid grid-nogutter">

                        <div class="col-12">
                            <div class="grid">
                                <div class="col-fixed" style="width:74px">
                                    <div class="paymentLogo">

                                        <img
                                                [src]="(payment.studio.hasOwnProperty('logo') && payment.studio.logo) ? payment.studio.logo['link'] : 'assets/images/sample_studio.png'"
                                                alt="studioLogo"
                                                style="border-radius: 50%; vertical-align:middle;"
                                        />

                                    </div>
                                </div>
                                <div class="col">
                                    <div class="grid">

                                        <div class="col-12 md:col-8 product-name-mobile-padding">
                                            <div class="product-name">
                                                      <span
                                                              class="tn-text-big"
                                                      >{{payment.studio.name}}
                                                      </span>
                                                <br>
                                                <p class="tn-text-normal tn-text-green" style="font-weight:400;">
                                                    <ng-template
                                                            [ngIf]="payment.status === 'CAPTURED'"
                                                    >
                                                        <span>Payment @ {{payment.updatedAt | dateParser:'dd-MM-yyyy'}}</span>
                                                    </ng-template>
                                                    <ng-template
                                                            [ngIf]="payment.status === 'REFUNDED'"
                                                    >
                                                        <span>Refunded @ {{payment.updatedAt | dateParser:'dd-MM-yyyy'}}</span>
                                                    </ng-template>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-4 md:flex short-id-web" style="flex-direction: row-reverse;">
                                            <span
                                                    [routerLink]="[ '..', 'bookings', payment.booking.id]"
                                                    class="tn-text-green tn-text-simple"
                                            >#{{payment.booking.shortId}}</span>
                                        </div>
                                        <div class="col-4 md:flex short-id-mobile" style="flex-direction: row-reverse;">
                                            <span
                                                    [routerLink]="[ '..', 'bookings', payment.booking.id]"
                                                    class="tn-text-green tn-text-normal"
                                            >#{{payment.booking.shortId}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-fixed payment-notes-padding " style="width:74px">
                        </div>
                        <div class="col payment-notes payment-notes-web">

                            <span class="tn-text-green tn-text-big"> <i class="till-noon-icon tnicon-Currency"></i> {{payment.paymentResponse ? parseFloat(payment.paymentResponse.orderAmount ) : '-' }}{{getCurrency(payment.booking.priceCurrency)}}</span>
                            <span class="tn-text-simple" > <i class="till-noon-icon tnicon-Calendar-Month"></i>{{payment.booking.bookingDate | dateParser:'dd-MM-yyyy'}}</span>
                            <span class="tn-text-simple" > <i class="till-noon-icon tnicon-Schedule"></i>{{payment.booking.bookingDate | dateParser:'HH:mm'}}</span>
                            <span class="tn-text-simple" > <i class="material-icons-outlined">timelapse</i> {{payment.booking.duration}} hour(s)</span>
                            <span class="tn-text-simple"
                                  *ngIf="payment.booking.bookingType === 'solo'"
                            > <i class="till-noon-icon tnicon-User-Outlined"></i> Solo
                                    </span>
                            <span class="tn-text-simple"
                                  *ngIf="payment.booking.bookingType === 'band'"
                            ><i class="till-noon-icon tnicon-Users"></i> Band {{payment.booking.team.name}}
                                    </span>

                            <span class="tn-text-simple"
                                  *ngIf="payment.booking.recurringDates && payment.booking.recurringDates.length > 0 "
                            > <i class="pi pi-history"></i> {{payment.booking.recurringDates.length - 1}} more dates</span>
                        </div>

                        <div class="col payment-notes payment-notes-mobile">

                            <span class="tn-text-green tn-text-big"> <i class="till-noon-icon tnicon-Currency"></i> {{payment.paymentResponse ? parseFloat(payment.paymentResponse.orderAmount ) : '-' }}{{getCurrency(payment.booking.priceCurrency)}}</span>
                            <span class="tn-text-simple"
                                  *ngIf="payment.booking.bookingType === 'solo'"
                            > <i class="till-noon-icon tnicon-User-Outlined"></i> Solo
                                    </span>
                            <span class="tn-text-simple"
                                  *ngIf="payment.booking.bookingType === 'band'"
                            ><i class="till-noon-icon tnicon-Users"></i> Band {{payment.booking.team.name}}
                                    </span>
                            <span class="tn-text-simple"
                                  *ngIf="payment.booking.recurringDates && payment.booking.recurringDates.length > 0 "
                            > <i class="pi pi-history"></i> {{payment.booking.recurringDates.length - 1}} more dates</span>
                            <span class="tn-text-simple" > <i class="till-noon-icon tnicon-Calendar-Month"></i>{{payment.booking.bookingDate | dateParser:'dd-MM-yyyy'}}</span>
                            <span class="tn-text-simple" ><i class="till-noon-icon tnicon-Schedule"></i> {{payment.booking.bookingDate | dateParser:'HH:mm'}}</span>
                            <span class="tn-text-simple" > <i class="material-icons-outlined">timelapse</i> {{payment.booking.duration}} hour(s)</span>
                        </div>

                    </div>

                </div>
            </ng-template>
        </p-dataView>
    </div>


</div>
