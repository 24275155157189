import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {ProfileService} from '../services/profile.service';
import {environment} from '../../environments/environment';
import {filter, map, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {HelperService} from '../services/helper.service';
import {InstrumentAndEquipmentService} from '../services/instruments.equipments.service';
import {ViewportScroller} from '@angular/common';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, AfterViewInit {

    public env = environment;

    public user;
    private userId;
    private userSlug;
    protected viewBandsInvites = false;

    public userFirstName;
    public userFacebookProfile;
    public userInstagramProfile;
    public userAbout;

    public selectedMusicRole;
    public instrumentOptions = [];
    public selectedInstrument;
    showNewMusicRoleRow = false;

    public musicRolesOptions = [];

    public allowEdit = false;
    public previewMode = false;
    public musicRolesMap: Map<string, {
        icon: string,
        label: string
    }>;

    public inviteDialog = false;
    public inviteEmail: string;
    public inviteSubmitted: boolean;
    public socialModalVisible = false;

    public allowEditInstruments = false;
    public moreDescription = false;

    constructor(
        private route: ActivatedRoute,
        private viewportScroller: ViewportScroller,
        private messageService: MessageService,
        private userService: UserService,
        private helperService: HelperService,
        private musicRolesService: InstrumentAndEquipmentService,
        private instrumentService: InstrumentAndEquipmentService,
        private profileService: ProfileService
    ) {
        this.userId = this.route.parent.snapshot.paramMap.get('id');
        this.userSlug = this.route.snapshot.paramMap.get('userSlug');
        this.user = this.userService.user$.pipe(
            filter((userData) => Object.keys(userData).length > 0),
            map((userData) => {
                userData.musicRoles = userData.musicRoles.map((musicRole) => {
                    musicRole.categoryLabel = this.musicRolesMap.get(musicRole.type).label;
                    musicRole.categoryIcon = this.musicRolesMap.get(musicRole.type).icon;

                    return musicRole;
                })
                return userData;
            }),
            tap((data) => {
                this.userId = data.id;
                this.userFirstName = data.name;
                this.userAbout = data.description;
                this.userFacebookProfile = data.facebookHandle ? data.facebookHandle : '';
                this.userInstagramProfile = data.instagramHandle ? data.instagramHandle : '';
            })
        );

        if (this.userId !== this.profileService.getUserData().id) {
            this.previewMode = true;
        }
    }


    ngOnInit(): void {
        this.musicRolesOptions = this.musicRolesService.getMusicRoles();
        this.musicRolesMap = this.musicRolesService.getMusicRolesMap();
        this.userService.fetchUser(this.userSlug ? this.userSlug : this.userId);

        this.helperService.fetchInstrumentTypes()
        .then((data) => {
          this.instrumentOptions = data.results.map((instrument) => {
            return { name : instrument.name , code : instrument.id };
          });
        });
    }

    ngAfterViewInit(): void {
        const scrollTo = this.route.snapshot.queryParamMap.get('scrollTo');
        if (scrollTo) {
            setTimeout(() => {
                this.viewBandsInvites = true;
                this.viewportScroller.scrollToAnchor(scrollTo);
            }, 500);
        }
    }


    imageUpload(event) {
        this.userService.addUserImage(this.userId, event.files[0], 'avatar')
            .then((data) => {
                this.profileService.setUserData(data);
                this.messageService.add({
                    key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'Image Uploaded', life: 3000
                });
            })
            .catch((err) => {
                const error = err.error;
                if (error.code === 500 && error.message === 'File too large') {
                    this.messageService.add({
                        key: 'globalToast',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'File is over 4mb!',
                        life: 3000
                    });
                }
            });
    }

    removeInstrument(instrument: any) {
        this.userService.removeInstrument(this.userId, instrument.id)
            .then((result) => {
                this.messageService.add({
                    key: 'globalToast',
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Instrument Removed',
                    life: 3000
                });
            });
    }

    removeMusicRole(musicRole: any, event) {
        event.stopPropagation();
        this.userService.removeMusicRole(this.userId, musicRole._id)
            .then((result) => {
                this.messageService.add({
                    key: 'globalToast',
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Instrument Removed',
                    life: 3000
                });
            });
    }

    setMusicRoleAsPrimary(musicRole: any) {
        if (this.allowEditInstruments) {
            this.userService.editMusicRole(this.userId, musicRole._id)
                .then((result) => {
                    this.allowEditInstruments = !this.allowEditInstruments;
                    this.messageService.add({
                        key: 'globalToast',
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Primary Instrument Selected',
                        life: 3000
                    });
                });
        }
    }


    saveInstrument() {
        const newInstrument = {
            name: this.profileService.getUserData().name + ' ' + this.selectedInstrument.name,
            level: 3,
            instrumentId: this.selectedInstrument.code
        };
        this.userService.addInstrument( this.userId , newInstrument)
            .then((result) => {
                this.messageService.add({
                    key: 'globalToast',
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Instrument Added',
                    life: 3000
                });
            });
    }

    saveMusicRole() {
        this.showNewMusicRoleRow = false;
        if (this.selectedMusicRole) {
            this.userService.addMusicRole(this.userId, {type: this.selectedMusicRole.type})
                .then((result) => {
                    this.messageService.add({
                        key: 'globalToast',
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Instrument Added',
                        life: 3000
                    });
                    this.selectedMusicRole = null;
                });
        }
    }

    saveUser() {
        if (!this.userFirstName || this.userFirstName.length === 0) {
            this.messageService.add({
                key: 'globalToast', severity: 'error', summary: 'Error', detail: 'You need to fill in your first name!', life: 3000});
        } else {
            this.userService.editUser(this.userId,
                {
                    name: this.userFirstName,
                    facebookHandle: this.userFacebookProfile.trim(),
                    instagramHandle: this.userInstagramProfile.trim(),
                    description: this.userAbout,
                }
            )
                .then((data) => {
                    this.profileService.setUserData(data);
                    this.allowEdit = !this.allowEdit;
                    this.messageService.add({
                        key: 'globalToast',
                        severity: 'success',
                        summary: 'Confirmed',
                        detail: 'Your profile changed',
                        life: 3000
                    });
                })
                .catch((err) => {
                    this.messageService.add({
                        key: 'globalToast', severity: 'error', summary: 'Error', detail: err.error.message, life: 3000});
                });
        }
    }

    shareClicked(buttonClicked: string, socialHande?: string) {
        if (buttonClicked === 'facebook') {
            window.open('https://www.facebook.com/' + socialHande, '_blank').focus();
        } else if (buttonClicked === 'instagram') {
            window.open('https://www.instagram.com/' + socialHande, '_blank').focus();
        }

    }

    hideDialog() {
        this.inviteEmail = '';
        this.inviteDialog = false;
        this.inviteSubmitted = false;
    }

    inviteUser() {
        if (this.inviteEmail && this.inviteEmail.trim().length > 0 ) {
            this.helperService.inviteUserToBandBeat(this.profileService.getUserData().name , this.inviteEmail)
                .then((data) => {
                    this.messageService.add({
                        key: 'globalToast', severity: 'success', summary: 'Successful', detail: 'User Invited', life: 3000
                    });
                    this.hideDialog();
                })
                .catch((err) => {
                    if(err.error.message === "User already a member"){
                        this.messageService.add({
                            key: 'globalToast', severity: 'error', summary: 'Error', detail: 'User already on Bandbeat', life: 3000
                        });
                        this.hideDialog();
                    }else if(err.error.message === '"invitemail" must be a valid email'){
                        this.messageService.add({
                            key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Please enter a valid email address', life: 3000
                        });
                        this.hideDialog();
                    }else{
                        this.messageService.add({
                            key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000
                        });
                        this.hideDialog();
                    }
                })
        } else {
            this.messageService.add({
                key: 'globalToast', severity: 'error', summary: 'Error', detail: 'Please add a valid email', life: 3000
            });
        }
    }

    openInvite() {
        this.inviteEmail = '';
        this.inviteDialog = true;
        this.inviteSubmitted = false;
    }

    closeModal(displayState) {
        this.socialModalVisible = false;
    }

}
